import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as FetchService from "../../services/FetchService/FetchService";
import { Table, DatePicker, Image } from "antd";
import moment from "moment";
import "../../styles/LineChart.css";

function LineChart() {
  const [orderplaced, setOrderplaced] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const dispatch = useDispatch();
  const handleMonthChange = (date, dateString) => {
    if (dateString !== "" && dateString !== undefined && dateString !== null) {
      console.log(moment(dateString), "dfataaa")
      setSelectedMonth(moment(dateString));
    } else {
      setDefaultMonth();
    }
  };


  const setDefaultMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    setSelectedMonth(`${year}-${month}`);
  };
  useEffect(() => {
    if (selectedMonth === null) {
      setDefaultMonth();
    }
  }, [selectedMonth]);

  useEffect(() => {
    // Replace this with your actual API call to fetch most ordered foods data
    const fetchData = async () => {
      // Example API call

      dispatch(FetchService.OrderList()).then((response) => {
        let filterRecords = response?.filter((item) => {
          console.log(moment(item?.createdAt).isSame(selectedMonth, "month"), "11")
          console.log(selectedMonth, "22")
          return (
            item !== null &&
            moment(item?.createdAt).isSame(selectedMonth, "month")
          );
        });

        const groupedData = filterRecords.reduce((acc, curr) => {
          const existingItem = acc.find(
            (item) => item.foodname === curr.foodname
          );
          if (existingItem) {
            existingItem.quantity += curr.quantity;
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);
        const sortedData = groupedData.sort((a, b) => b.quantity - a.quantity);

        setOrderplaced(sortedData);
      });
    };

    fetchData();
  }, [selectedMonth, dispatch]);

  // const filteredData = orderplaced.filter((item) => {
  //   console.log(item, "item")
  //   return moment(item.date).isSame(selectedMonth, "month");
  // });

  const columns = [
    {
      title: <span className="custom-address-title">Food Name</span>,
      dataIndex: "foodname",
      className: "custom-name-column",
      key: "foodname",
      // ...getColumnSearchProps("username"),

      render: (img, record) => (
        <div
          style={{
            textAlign: "center",
          }}
        >

          <Image
            src={`https://foodorde-api.aleaspeaks.com/${record.foodimage}`}
            preview={false}
            width={30}
            height={30}
            style={{
              borderRadius: "20px",
            }}
          />

          <span style={{ margin: "5px" }}>{img}</span>

          {/* <span style={{ margin: "5px" }}>{img}</span> */}
        </div>
      ),
    },
    {
      title: <span className="custom-title">Quantity</span>,
      dataIndex: "quantity",
      key: "quantity",
      className: "quantityTable",
    },
  ];
  return (
    <>
      <h1> Most ordered food Item </h1>
      <DatePicker
        defaultValue={selectedMonth}
        onChange={handleMonthChange}
        picker="month"
      />
      <Table
        columns={columns}
        dataSource={orderplaced}
        pagination={true}
        className="foodTable"
      />
    </>
  );
}

export default LineChart;