// userActions.js
export const setUserType = (userType) => ({
  type: "SET_USER_TYPE",
  payload: userType,
});

export const setUserID = (userID) => ({
  type: "SET_USER_ID",
  payload: userID,
});
export const setAddItemCart = (itemCart) => ({
  type: "SET_ITEM_CART",
  payload: itemCart,
});
export const setRating = (Rating) => ({
  type: "SET_RATING",
  payload: Rating,
});
export const resetUserState = () => ({
  type: "RESET_USER_STATE",
});
export const setUserDetail = (details) => ({
  type: "SET_USERNAME_DETAIL",
  payload: details,
});

export const resetUserDetailState = () => ({
  type: "RESET_USERDETAIL_STATE",
});