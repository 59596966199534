import React, { useEffect, useState } from "react";
import { Form, Input, Select, Space, Button, Modal, Table } from "antd";
import * as DispatchService from "../../services/DispatchService/DispatchService";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import '../../styles/Infra.css'
const { Option } = Select
const Infra = () => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState(false)
    const [requirmentData, setRequirmentData] = useState([])
    const loggedInUserRole = useSelector((state) => state.user.userID);
    const getuserid = loggedInUserRole;
    const dispatch = useDispatch();
    const items = [
        {
            value: "Hardware",
            label: "Hardware",
        },
        {
            value: "Stationary",
            label: "Stationary",
        },
    ];

    const onFinish = (values) => {

        let data = {
            user_id: getuserid,
            description: values.description,
            category: values.category,
            location: values.location
        }
        dispatch(DispatchService.InfraMessage(data)).then((response) => {
            if (response) {
                Swal.fire({
                    title: "Succesfully Sent",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setModal(false)
                        handleGetData()
                    }
                })
            } else {
                Swal.fire({
                    title: "Error in send mail",
                    icon: "error",
                });

            }
        })
        form.resetFields();
    }
    const handleGetData = () => {

        dispatch(DispatchService.getRequirment({ getuserid })).then((response) => {
            setRequirmentData(response)
        })
    }
    useEffect(() => {
        handleGetData()
    }, [])
    const options = [
        {
            key: "OG",
            label: "OG",
        },
        {
            key: "DA",
            label: "DA",
        },
    ];

    const handleRequirment = () => {
        setModal(true)
    }

    const handleCancel = () => {
        setModal(false)
    }


    const listColumn = [
        {
            title: "S. No.",
            dataIndex: "S_No.",
            key: "S_No",
            render: (text, record, index) => index + 1,
        },
        {
            title: <span className="custom-title">Requirement detail </span>,
            dataIndex: "description",
            key: "description",
            render: (time, rec) => (
                <div
                    style={{
                        display: "flex",
                        marginLeft: "60px",
                    }}
                >

                    <span style={{ margin: "5px" }}>{rec.description}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title"> Category</span>,
            dataIndex: "category",
            key: "category",
            render: (time, rec) => (
                <div className="timing">

                    <span>{rec.category}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Location</span>,
            dataIndex: "location",
            key: "location",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.location}</span>
                </div>
            ),
        },
    ]
    return (
        <>
            <div className='InfraWrapper'>
                <div style={{ padding: "0 1rem" }}>
                    <Button type="primary" onClick={handleRequirment}>Requirement</Button>
                </div>
                <Modal title="Requisition Form" open={modal} onCancel={handleCancel} footer={null}>
                    <Form className="MainFormTime" onFinish={onFinish} form={form}>
                        <Form.Item label=" Needed item" name="description" rules={[
                            {
                                required: true,
                                message: 'Please write description for needed item!',
                            },
                        ]}>

                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            name="category"
                            label="Category"
                            className="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select category!",
                                },
                            ]}
                        >
                            <Select

                                allowClear
                                className="inFraSelect"
                                // style={{
                                //     width: "100%",
                                // }}
                                placeholder="Please select"
                                optionLabelProp="label"
                                options={items}
                                optionrender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.value}

                                        </span>

                                    </Space>
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            name="location"
                            label="Location"
                            className="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select option!",
                                },
                            ]}
                        >
                            <Select
                                defaultValue="Please Select"
                                style={{ width: 120 }}

                            >
                                {options.map((item) => (
                                    <Option key={item.key} value={item.key}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="submit" htmlType="submit">Submit</Button>

                        </Form.Item>
                    </Form>
                </Modal>
                <div className='tableWrapper'>
                    {requirmentData.length > 0 ? (
                        <Table
                            className="time-table"
                            columns={listColumn}
                            pagination={true}
                            dataSource={requirmentData}
                        />
                    ): ""}
                   
                </div>
            </div>
        </>
    )
}
export default Infra