// rootReducer.js
import { combineReducers } from "redux";
import userReducer from "./userReducer";
import itemReducer from "./itemReducer";
import ratingReducer from "./ratingReducer";
import userDetailReducer from "./userDetailReducer";
const rootReducer = combineReducers({
  user: userReducer,
  item: itemReducer,
  rating: ratingReducer,
  userDetail: userDetailReducer
});

export default rootReducer;
