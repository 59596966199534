import React from 'react'
import { Row, Col, Button } from 'antd'
import { useNavigate } from 'react-router'

const Stores = () => {
    const navigate = useNavigate()
    const handleHardware = () => {
        navigate('/hardware')
    }
    const handleStationary = () => {
        navigate('/stationary')
    }
    const handleFood = () => {
        navigate('/food')
    }
     return (
         <Row>
             <Col span={24}>
                 <Button onClick={handleHardware}>Hardware</Button>
                 <Button onClick={handleStationary}>Stationary</Button>
                 <Button onClick={handleFood}>Food</Button>
             </Col>
         </Row>
     )
}
 
export default Stores