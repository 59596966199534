import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Button, Image } from "antd";
import { NavLink } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import "../../styles/header.css";
import { useDispatch, useSelector } from "react-redux";
import { resetUserState } from "../../store/actions";
import { useNavigate } from "react-router-dom";
function Header({ name, subName, toggleDrawer }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const userData = useSelector((state) => state);
  let getuserdetail = userData?.userDetail?.userdetails;
  let getitem = userData.user.userType;
  const LogoutUser = () => {
    localStorage.clear();
    dispatch(resetUserState());
    navigate("/");
  };
  return (
    <>
      {open ? (
        <Row gutter={[24, 0]}>
          <Col span={24} md={6} className="header">
            <div>
              <MenuOutlined onClick={toggleDrawer} />
            </div>
            <div className="header-wrapper">
              <div className="ant-page-header-heading">
                <span
                  className="ant-page-header-heading-title"
                  style={{ textTransform: "capitalize" }}  >
                  {subName.replace("/", "")}
                </span>
              </div>
            </div>
          </Col>
          <Col span={24} md={18} className="header-control"></Col>
        </Row>
      ) : (
        <Row gutter={[24, 0]}>
          <Col span={6} md={6}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/">Pages</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
                <span
                  className="ant-page-header-heading-title"
                  style={{ textTransform: "capitalize" }}
                >
                  {name.replace(/[_-]/g, " ")}
                </span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={6}></Col>
          <Col span={4}></Col>
          <Col span={8} className="text-right">
            {getitem === 1 || getitem === "1" ? (
              <>
                <Row>
                  <Col span={20}>
                    <h3>{getuserdetail?.username}</h3>
                    <p>{getuserdetail?.email}</p>
                  </Col>
                  <Col span={4}>
                    <Image
                      className="headerImage"
                      preview={false}
                      src={getuserdetail?.userimage}
                      alt="image"
                      width={"50"}
                      height={"50"}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <Button onClick={() => LogoutUser()}>LogOut</Button>
            )}
          </Col>
          <Col span={24} md={18} className="header-control"></Col>
        </Row>
      )}
    </>
  );
}

export default Header;
