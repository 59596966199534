// Import necessary dependencies
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import rootReducer from "./store/reducers/rootReducer";
import { encryptTransform } from "redux-persist-transform-encrypt";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

// Configure Redux Persist
const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    transforms: [encryptTransform({ secretKey: "userType" })],
  },
  rootReducer
);

const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);
localStorage.removeItem('persist:root’');
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
