import React from 'react';
import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import '../../styles/footer.css';
function Footer() {
  const { Footer: AntFooter } = Layout;
  return (
    <AntFooter style={{ background: "#fafafa" ,marginTop:"50px"}}>
      <Row className="just">
        <Col sm={24} md={12} lg={12}>
          <div className="copyright">
            © 2024, made with
            {<HeartFilled />} by
            <a href="https://www.aleaitsolutions.com/" className="font-weight-bold" target="_blank" rel="noopener noreferrer">
              AleaIT Solutions Pvt Ltd.
            </a>
            All rights reserved.
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">
                <a
                  href="https://www.aleaitsolutions.com/about-us/"
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.aleaitsolutions.com/contact-us/"
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
