import axios from "axios";
import Logout from "../src/Helpers/logout";
import Swal from "sweetalert2";
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  const token = `Bearer ${localStorage.getItem("userToken")}`;
  config.headers.Authorization = token;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (response.status === 208 || response.status === "208") {
      Swal.fire({
        title: "Your session has  been expired",
        icon: "error",
      }).then((result) => {
        if (result.isConfirmed) {
          Logout();
     
        }
      });
    } else {
      return response;
    }
  },

  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

export default axios;
