import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/reset.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import FormTicket from "./pages/FormTicket";
import UpdateTicket from "./pages/UpdateTicket";
import "../src/style.css";
import ListTicket from "./pages/ListTicket";
import UserPanel from "./pages/UserPanel";
import Userpage from "./pages/Users/Userpage";
import Shoppingcart from "./pages/Users/Shoppingcart";
import ViewOrder from "./pages/Users/ViewOrder";
import Orderlist from "./pages/Orderlist";
import TimeList from "./pages/TimeList";
import AssginedTicket from './pages/Users/AssginedTicket'
import Infra from './pages/Users/Infra'
import { useSelector } from "react-redux";
import Announcement from './pages/Announcement'
import Settings from './pages/Settings'
import OrderTiming from './pages/Users/OrderTiming'
import Stores from "./pages/inventory/Store/Stores";
import Hardware from './pages/inventory/Store/Hardware'
import Stationary from './pages/inventory/Store/Stationary'
import Category from './pages/inventory/Store/Category'
import Food from './pages/inventory/Store/Food'
import Other from './pages/inventory/Store/Other'

function App() {
  const loggedInUserRole = useSelector((state) => state.user.userType);
  const isAdmin = loggedInUserRole === 2;
  const isCustomer = loggedInUserRole === 1;
  return (
    <>
      {isAdmin ? (
        <Main>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/form_ticket" element={<FormTicket />} />
            <Route path="/list_ticket" element={<ListTicket />} />

            <Route path="/update_ticket" element={<UpdateTicket />} />
            <Route path="/user_panel" element={<UserPanel />} />
            <Route path="/order_list" element={<Orderlist />} />
            <Route path="/time_list" element={<TimeList />} />
            <Route path="/announcement" element={<Announcement />} />

            <Route path="/stores" element={<Stores />} />
            <Route path="/hardware" element={<Hardware />} />
            <Route path="/stationary" element={<Stationary />} />
            <Route path="/food" element={<Food />} />
            <Route path="/other" element={<Other />} />
            <Route path="/category" element={<Category />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </Main>
      ) : isCustomer ? (
        <Main>
          <Routes>
            <Route path="/order_food" element={<Userpage />} />
            <Route path="*" element={<Navigate to="/order_food" />} />
            <Route path="/shopping_cart" element={<Shoppingcart />} />
            <Route path="/view_order" element={<ViewOrder />} />
            <Route path="/assgined_ticket" element={<AssginedTicket />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route path="/order_timing" element={<OrderTiming />} />
              <Route path="/infra" element={<Infra />} />
          </Routes>
        </Main>
      ) : (
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="*" element={<SignIn />} />
        </Routes>
      )}
    </>
  );
}

export default App;
