
import React,{useState,useEffect} from "react";
import {Form , Input , Select, Space, Button,DatePicker,Modal,Card,Table,Image} from "antd"
import { useForm } from "antd/es/form/Form";
import Swal from "sweetalert2";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as DispatchService from "../../../services/DispatchService/DispatchService";
import * as FetchService from "../../../services/FetchService/FetchService";
import { GoChecklist } from "react-icons/go";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
const {Option} =Select
const Food = () => {
    const [modal, setModal] = useState(false);
    const [form] = useForm()
    const [updateForm] = Form.useForm();
    const dispatch = useDispatch();
    const [food, setFood] = useState([])
    const [listofUser, setListofUser] = useState(false)
    const [category, setCategory] = useState([])
    const [allocateModal, setAllocateModal] = useState(false)
    const [items, setItems] = useState([]);
    const [alotUser, setAlotUser] = useState([])
    const [foodId, setFoodId] = useState('')
    const [updateModal, setUpdateModel] = useState(false)

    const [otherId, setOtherId] = useState('')
    const [categoryID, setCategoryID] = useState('')
    const [initialValues, setInitialValues] = useState({ quantity: '', itemName: '', category: '', vendorName: '' });
    


    const categoryData = () => {
        dispatch(FetchService.getCategory()).then((response) => {
            const transformedOptions = response.map((item) => ({
                label: item.category,
                value: item._id,
            }));


            setCategory(transformedOptions)

        })
    }
    const handleFood = () => {
        setModal(true)
    }
    // Function to handle modal cancellation
    const handleCancel = () => {
        setModal(false)
    }

    const handleSubmit = (values) => {
        // Destructure the values object to extract individual fields
        const { date, vendorName, quantity, itemName, selectOption } = values;

        // Regex to check if the string contains only spaces
        const isValidName = (name) => !/^\s*$/.test(name);
        const isValidQuantity = (number) => /^\d+$/.test(number);
        // Validate vendorName and itemName
        if (!isValidName(vendorName) || !isValidName(itemName) || !isValidQuantity(quantity)) {
            Swal.fire({
                title: "Invalid Input",
                text: "Please enter a valid name",
                icon: "error",
            });
            return;
        }

        // Format the date to 'YYYY-MM-DD'
        const formattedDate = date.format('YYYY-MM-DD');
        if (quantity > 0) {
            // Combine the extracted data into a new object
            const combinedData = {
                date: formattedDate,
                vendorName,
                quantity,
                itemName,
                selectOption: selectOption.value
            };


            // Check if combinedData is not null before proceeding
            if (combinedData) {
                // Dispatch the action to add hardware stock and handle the response
                dispatch(DispatchService.addingFoodStock(combinedData)).then(
                    (response) => {
                        // Check the response status and show appropriate messages
                        if (response.status === 204 || response.message === "Insufficient quantity in the selected category") {
                            Swal.fire({
                                title: "Insufficient quantity in the selected category",
                                icon: "error",
                            });
                            return;
                        } else {
                            Swal.fire({
                                title: "Successfully Added",
                                icon: "success",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    // Close the modal and refresh data on confirmation
                                    setModal(false);
                                    getdata();
                                }
                            });
                        }



                    }
                );
            }
        }
        else {
            Swal.fire({
                title: "Invalid Input",
                text: "Please enter a valid quantity",
                icon: "error",
            });
            return;
        }
        // Reset the form fields after submission
        form.resetFields();
    };


    const getdata = () => {
        dispatch(FetchService.getAllFoodData()).then((response) => {
            if (response === 204 || response === "204") {
                Swal.fire({
                    title: "No enoungh quantity remain",
                    icon: "warning"
                })
            } else {
                setFood(response)
            }

        })
    }

    useEffect(() => {
        getdata()
        categoryData()

    }, [])
    // E
    function disabledDate(current) {
        // Disable all dates before today's date
        return current && current < moment().startOf("day");
    }
    const onSearch = (value) => {
        // console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());



    const handleUpdate = (data) => {
        setUpdateModel(true)
        console.log(data, "dataaa")
        setOtherId(data._id)
        const intinaldata = ({
            //     Allocation: data.Allocation,
            quantity: data.quantity,
            itemName: data.itemName,
            selectOption: data.categoryName,
            vendorName: data?.vendorName

        });
        setCategoryID(data.category)
        updateForm.setFieldsValue(intinaldata);
    }

    const handleUpdateCancel = () => {
        setUpdateModel(false)
        setInitialValues({ vendorName: '', quantity: '', itemName: '', selectOption: '', })
    }


    const handleUpdateSubmit = (values) => {

        const { date, vendorName, itemName, selectOption, quantity, Allocation } = values;
        const formattedDate = date.format('YYYY-MM-DD');

        const data = {
            formattedDate, vendorName, itemName, selectOption: selectOption?.value ? selectOption?.value : categoryID, quantity,
            otherId
        }

        dispatch(DispatchService.updatedFoodStock({ data })).then((response) => {
            if (response.message === "Other Stock  Updated successfully") {
                Swal.fire({
                    title: "Succesfully Updated",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUpdateModel(false)
                        // setInitialValues({ Allocation: '', quantity: '', itemName: '', selectOption: '', })
                        getdata()
                    }
                })
            }
        })
    }


    const handleDelete = (data) => {
        Swal.fire({
            title: "Are you sure you want to delete this item?",
            text: "This action cannot be undone",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(data, "delete dataaa")
                const foodId = data._id
                dispatch(DispatchService.deletefood({ foodId })).then((response) => {
                    if (response.status === 200 || response.message === "food and related data deleted successfully") {
                        Swal.fire({
                            title: "Succesfully Deleted",
                            icon: "success",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setModal(false)
                                getdata()
                            }

                        })

                    } else {
                        // console.log('failed to delete 1')
                    }

                })
            } else {
                // console.log("failed to delete 2")
            }
        })
    }
    const columns = [
        {
            title: "S. No.",
            dataIndex: "S_No.",
            key: "index",
            render: (text, record, index) => index + 1,
        },
        {
            title: <span className="custom-title">Name</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.itemName}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">vendor Name</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.vendorName}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Quantity</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.quantity}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Remaining Quantity</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.remainingQuantity}</span>
                </div>
            ),
        },

        {
            title: <span className="custom-title">Allocate Food</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <IoIosAddCircleOutline onClick={() => handleAllocate(rec)} />
                </div>
            ),
        },
        {
            title: <span className="custom-title">Allocated User</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <GoChecklist onClick={() => allocateFooddata(rec)} />
                </div>
            ),
        },

        {
            title: <span className="custom-title">Action</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <Button className="DeleteButton" onClick={() => handleDelete(rec)}><MdDelete />Delete</Button>
                    <Button className="UpdateButton" onClick={() => handleUpdate(rec)} > <FaEdit />Edit</Button>
                </div>
            ),
        },

    ];


    const handleAllocate = (data) => {
        setFoodId(data)
        dispatch(FetchService.UserDetail())
            .then((response) => {
                setItems(response);
            })
        setAllocateModal(true)
    }

    const allocateFooddata = (rec) => {
        const id = rec._id;
        setListofUser(true)

        dispatch(FetchService.getAllocatedFoodUser())
            .then((response) => {
                const allocatedUsers = response.filter(item => item.hardwareId === id)
                setAlotUser(allocatedUsers)
            })
    }
    const options = [
        {
            key: "OG",
            label: "OG",
        },
        {
            key: "DA",
            label: "DA",
        },
    ];
    const onFinish = (value) => {

        const quantity = value?.allocateQuantity;
        const option = value?.option;
        const userId = value?.username?.value


        // Regex to check if the string contains only spaces
        const isValidInput = (input) => !/^\s*$/.test(input);
        const isValidQuantity = (number) => /^\d+$/.test(number);
        // Validate userId and option
        if (!isValidInput(userId) || !isValidInput(option) || !isValidQuantity(quantity)) {
            Swal.fire({
                title: "Invalid Input",
                text: "Please enter a valid username and option",
                icon: "error",
            });
            return;
        }
        if (quantity > 0) {
            const details = {
                quantity,
                userId,
                foodId,
                option,

            }

            dispatch(DispatchService.allocateFood(details)).then(
                (response) => {
                    if (response.status === "204" || response.status === 204 || response.message === "Not enough remaining quantity to allocate") {

                        Swal.fire({
                            title: "No enoungh quantity ",
                            icon: "warning"
                        })
                    } else {


                        Swal.fire({
                            title: "Succesfully Added",
                            icon: "success",
                        }).then(() => {
                            getdata()
                        })
                    }
                })
        }
        else {
            Swal.fire({
                title: "Invalid Input",
                text: "Please enter a valid quantity",
                icon: "error",
            });
            return;
        }
        form.resetFields()
        setAllocateModal(false)
    }
    const handleCancelAllocated = () => {
        setAllocateModal(false)
    }
    // Function to handle cancellation of list of users modal
    const cancle = () => {
        setListofUser(false)
    }
    useEffect(() => {
        dispatch(FetchService.UserDetail())
            .then((response) => {
                setItems(response);
            })
    }, [])
    const listColumn = [
        {
            title: "S. No.",
            dataIndex: "S_No.",
            key: "index",
            render: (text, record, index) => index + 1,
        },
        {
            title: <span className="custom-title">User Name</span>,
            dataIndex: "username",
            key: "username",
            render: (time, rec) => (
                <div
                    style={{
                        display: "flex",
                        marginLeft: "60px",
                    }}
                >
                    <Image
                        src={rec.userimage}
                        preview={false}
                        width={30}
                        height={30}
                        style={{
                            borderRadius: "20px",
                        }}
                    />
                    <span style={{ margin: "5px" }}>{rec.username}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Allocated Place</span>,
            dataIndex: "username",
            key: "username",
            render: (time, rec) => (
                <div className="timing">

                    <span>{rec.allocatedPlace}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Quantity</span>,
            dataIndex: "username",
            key: "username",
            render: (time, rec) => (
                <div className="timing">

                    <span>{rec.quantity}</span>
                </div>
            ),
        },
    ]
    return (
        <>
<div>
                <Button type="primary" onClick={handleFood}>Add Food</Button>
            </div>
            <Modal title="Food Detail" open={modal} onCancel={handleCancel} footer={null}>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[{ required: true, message: 'Please select the date!' }]}
                    >
                        <DatePicker disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item
                        label="Vendor Name"
                        name="vendorName"
                       
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Item Name"
                        name="itemName"
                        rules={[{ required: true, message: 'Please input the item name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Select Category" name="selectOption" rules={[{ required: true, message: 'Please Select Category!' }]}>

                        <Select
                            style={{
                                width: 300,
                            }}
                            optionFilterProp="children"
                            showSearch
                            onSearch={onSearch}
                            filterOption={filterOption}
                            placeholder="Select option"
                            labelInValue={true}
                            options={category}

                            // dropdownRender={(menu) => <>{menu}</>}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.value}>
                                        {option.data.label}
                                    </span>
                                </Space>
                            )}
                        >

                        </Select>

                    </Form.Item>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[{ required: true, message: 'Please input the quantity!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit">Add</Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Card>
                {food.length > 0 ? (
                    <Table
                        // className="time-table"
                        columns={columns}
                        pagination={true}
                        dataSource={food}

                    />
                ) : ""}

            </Card>
            <Modal title="Allocate food" open={allocateModal} onCancel={handleCancelAllocated} footer={null}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        label="Select User"
                        name='username'
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "Please select option!",
                        //     },
                        // ]}
                    >
                        <Select
                            style={{
                                width: 300,
                            }}
                            placeholder="Select option"
                            labelInValue={true}
                            // onChange={hideSelectedValues}
                            dropdownRender={(menu) => <>{menu}</>}
                        >
                            {items
                                .sort((a, b) => a.username.localeCompare(b.username))
                                .map((data, index) => (
                                    <>
                                        <Option
                                            key={index}
                                            value={data._id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Image
                                                preview={false}
                                                src={data.userimage}
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                            <span style={{ marginLeft: "18px" }}>
                                                {data.username}
                                            </span>
                                        </Option>
                                    </>
                                ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Quantity"
                        name="allocateQuantity"
                        rules={[{ required: true, message: 'Please input the quantity!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="option"
                        label="Option"
                        className="username"
                        rules={[
                            {
                                required: true,
                                message: "Please select option!",
                            },
                        ]}
                    >
                        <Select
                            defaultValue="Please Select"
                            style={{ width: 120 }}

                        >
                            {options.map((item) => (
                                <Option key={item.key} value={item.key}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Allocate</Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="Allocated User" open={listofUser} onCancel={cancle} footer={null} className="hardware-modal">
                {alotUser.length > 0 ? (
                    <Table
                        // className="time-table"
                        columns={listColumn}
                        pagination={true}
                        dataSource={alotUser}

                    />
                ) : ""}

            </Modal>

            <Modal title="Update hardware store" open={updateModal} onCancel={handleUpdateCancel} footer={null}>
                <Form form={updateForm} layout="vertical" onFinish={handleUpdateSubmit}>

                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[{ required: true, message: 'Please select the date!' }]}
                    >
                        <DatePicker disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item
                        label="Vendor Name"
                        name="vendorName"

                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Item Name"
                        name="itemName"
                        rules={[{ required: true, message: 'Please input the item name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Select Option" name="selectOption" rules={[{ required: true, message: 'Please input the quantity!' }]}>
                        <Select
                            style={{
                                width: 300,
                            }}
                            optionFilterProp="children"
                            showSearch
                            onSearch={onSearch}
                            filterOption={filterOption}
                            placeholder="Select option"
                            labelInValue={true}
                            options={category}
                            // dropdownRender={(menu) => <>{menu}</>}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.value}>
                                        {option.data.label}
                                    </span>
                                </Space>
                            )}
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[{ required: true, message: 'Please input the quantity!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Update</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default Food