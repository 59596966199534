import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Row, Col, Typography, Form, Input, Image } from "antd";
import signinbg from ".././assets/images/burgerBg.png";
import * as DispatchService from "../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setUserType } from "../store/actions/index";
import { setUserID } from "../store/actions/index";
import { setUserDetail } from "../store/actions/index";
import "../styles/signin.css";
const { Title } = Typography;
const { Content } = Layout;
function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = (values) => {
    dispatch(DispatchService.SignIn(values))
      .then((response) => {
        if (response.admindata) {
          let userType = response.admindata.status;
          let userToken = response.adminToken;
          let userId = response.admindata._id;
          localStorage.setItem("userToken", userToken);
          dispatch(setUserID(userId));
          dispatch(setUserType(userType));
          navigate("/dashboard");
        } else if (response.userdata) {
          let userType = response.userdata.status;
          console.info("usertype", response.userdata);
          let userId = response.userdata._id;
          let userToken = response.userToken;
          dispatch(setUserID(userId));
          dispatch(setUserType(userType));
          localStorage.setItem("userToken", userToken);
          navigate("/order_food", {
            state: { item: userId, userDetail: response.userdata },
          });
        } else {
          if (
            response === "User not found" ||
            response.status === 202 ||
            response === "Invalid password"
          ) {
            toast.error("Wrong email and password please write correct one", {
              autoClose: 1000,
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  let data = params.get("data");
  if (data !== null) {
    dispatch(DispatchService.checkToken(data))
      .then((response) => {

        let userType = response.user.status;
        let userId = response.user._id;
        let userToken = response.userToken;
        dispatch(setUserID(userId));
        dispatch(setUserType(userType));
        // dispatch(setUserDetail(response.userdata));
        localStorage.setItem("userToken", userToken);
        navigate("/", {
          state: { item: userId },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  if (data !== null) {
    return <></>;
  }
  return (
    <>
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 6, offset: 2 }}
            md={{ span: 12 }}
          >
            <Title className="mb-15" style={{ margin: "30px" }}>
              Sign In
            </Title>
            <Title className="font-regular text-muted" level={5}>
              Enter your email and password to sign in
            </Title>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
           
              className="row-col"
            >
              <Form.Item
                className="username"
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                className="username"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="input password" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  SIGN IN
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            className="sign-img"
            style={{ padding: 12 }}
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            {/* <div className="sign-img"></div> */}
            <Image src={signinbg} alt="" preview={false} className="imagesss" />
          </Col>
        </Row>
      </Content>
      <ToastContainer />
    </>
  );
}

export default SignIn;
