import React, { useEffect, useState } from "react";
import { Space, Form, Input, Button, DatePicker, Modal, Table, Card, Select, Image } from "antd";
import { useDispatch } from "react-redux";
import * as DispatchService from "../../../services/DispatchService/DispatchService";
import * as FetchService from "../../../services/FetchService/FetchService";
import { IoIosAddCircleOutline } from "react-icons/io";
import { PiUserList } from "react-icons/pi";
import moment from "moment";
import '../../../styles/Stationary.css'
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
const { Option } = Select
const Stationary = () => {
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false)
    const [allocateModal, setAllocateModal] = useState(false)
    const [stationary, setStatinoary] = useState([])
    const [category, setCategory] = useState([])
    const [items, setItems] = useState([])
    const [alotUser, setAlotUser] = useState([])
    const [stationaryId, setStationaryId] = useState('')
    const [listofUser, setListofUser] = useState(false)
    const [updateModal, setUpdateModel] = useState(false)
    
    const [otherId, setOtherId] = useState('')
    const [categoryID, setCategoryID] = useState('')
    const [initialValues, setInitialValues] = useState({ quantity: '', itemName: '', category: '', vendorName:'' });
    const onSearch = (value) => {
        // console.log('search:', value);
    };
    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const categorydata = () => {
        dispatch(FetchService.getCategory()).then((response) => {
            const transformedOptions = response.map((item) => ({
                label: item.category,
                value: item._id,
            }));
            setCategory(transformedOptions)
        })
    }
    const handleStationary = () => {
        setModal(true)
     
    }
    const handleCancel = () => {
        setModal(false)
    }
    const handleCancelAllocated = () => {
        setAllocateModal(false)
    }
    const cancle = () => {
        setListofUser(false)
    }
    const options = [
        {
            key: "OG",
            label: "OG",
        },
        {
            key: "DA",
            label: "DA",
        },
    ];

    const handleSubmit = (values) => {
        const { date, vendorName, quantity, itemName, selectOption } = values;

        // Regex to check if the string contains only spaces
        const isValidName = (name) => !/^\s*$/.test(name);
        const isValidQuantity = (number) => /^\d+$/.test(number);
        // Validate vendorName and itemName
        if (!isValidName(vendorName) || !isValidName(itemName) || !isValidQuantity(quantity)) {
            Swal.fire({
                title: "Invalid Input",
                icon: "error",
            });
            return;
        }

        // Format the date to 'YYYY-MM-DD'
        const formattedDate = date.format('YYYY-MM-DD');
        // Combine the extracted data into a new object
        if (quantity > 0) {
            const combinedData = {
                date: formattedDate,
                vendorName,
                quantity,
                itemName,
                selectOption: selectOption.value
            };

            // Dispatch the action to add stock and handle the response
            dispatch(DispatchService.addingStock(combinedData)).then((response) => {
                if (response.status === 204 || response.message === "Insufficient quantity in the selected category") {
                    Swal.fire({
                        title: "Insufficient quantity in the selected category",
                        icon: "error",
                    });
                    return;
                } else {
                    Swal.fire({
                        title: "Successfully Added",
                        icon: "success",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Close the modal and refresh data on confirmation
                            setModal(false);
                            getdata();
                        }
                    });
                }
            });
        } else {
            Swal.fire({
                title: "Invalid Input",
                text: "Please enter a valid quantity",
                icon: "error",
            });
            return;
        }
        // Reset the form fields after submission
        form.resetFields();
    };

    const getdata = () => {
        dispatch(FetchService.getStationary()).then((response) => {
            setStatinoary(response)

        })
    }
    useEffect(() => {
        getdata()
        categorydata()
    }, [])

    const handleAllocate = (data) => {
        const id = data._id;

        setStationaryId(data)

        dispatch(FetchService.UserDetail())
            .then((response) => {
                setItems(response);
            })
        setAllocateModal(true)
    }

    useEffect(() => {
        dispatch(FetchService.UserDetail())
            .then((response) => {
                setItems(response);
            })
    }, [])
    const onFinish = (value) => {
        const quantity = value.alocateStationaryQuantity;
        const option = value.option;
        const userId = value.username.value;

        // Regex to check if the string contains only spaces
        const isValidInput = (input) => !/^\s*$/.test(input);
        const isValidQuantity = (number) => /^\d+$/.test(number);

        // Validate userId and option
        if (!isValidInput(userId) || !isValidInput(option) || !isValidQuantity(quantity)) {
            Swal.fire({
                title: "Invalid Input",
                text: "Please enter a valid username and option",
                icon: "error",
            });
            return;
        }
        if (quantity > 0) {
            const details = {
                quantity,
                userId,
                stationaryId,
                option,
            };

            dispatch(DispatchService.allocateStationary(details)).then((response) => {
                if (response.status === "204" || response.status === 204 || response.message === "Not enough remaining quantity to allocate") {
                    Swal.fire({
                        title: "Not enough quantity",
                        icon: "warning",
                    });
                } else {
                    Swal.fire({
                        title: "Successfully Added",
                        icon: "success",
                    }).then(() => {
                        getdata();
                    });
                }
            });
        }
        else {
            Swal.fire({
                title: "Invalid Input",
                text: "Please enter a valid quantity",
                icon: "error",
            });
            return;
        }
        setAllocateModal(false);
        form.resetFields();
    };


    const allocateStationaryData = (rec) => {
        const id = rec._id;
        setListofUser(true)

        dispatch(FetchService.getAllocatedList())
            .then((response) => {
                const allocatedUsers = response.filter(item => item.StationaryId === id)
                setAlotUser(allocatedUsers)
            })
    }

    const handleUpdate = (data) => {
        setUpdateModel(true)
        setOtherId(data._id)
        const intinaldata = ({
        //     Allocation: data.Allocation,
            quantity: data.quantity,
            itemName: data.itemName,
            selectOption: data.categoryName,
        vendorName: data?.vendorName

        });
        setCategoryID(data.category)
        updateForm.setFieldsValue(intinaldata);
    }

    const handleUpdateCancel = () => {
        setUpdateModel(false)
        setInitialValues({ vendorName: '', quantity: '', itemName: '', selectOption: '', })
    }


    const handleUpdateSubmit = (values) => {
  
        const { date, vendorName, itemName, selectOption, quantity, Allocation } = values;
        const formattedDate = date.format('YYYY-MM-DD');

        const data = {
            formattedDate, vendorName, itemName, selectOption: selectOption?.value ? selectOption?.value : categoryID, quantity, 
            otherId
        }

        dispatch(DispatchService.updatedStationaryStock({ data })).then((response) => {
            if (response.message === "Other Stock  Updated successfully") {
                Swal.fire({
                    title: "Succesfully Updated",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUpdateModel(false)
                        // setInitialValues({ Allocation: '', quantity: '', itemName: '', selectOption: '', })
                        getdata()
                    }
                })
            }
        })
    }

    const handleDelete = (data) => {
        Swal.fire({
            title: "Are you sure you want to delete this item?",
            text: "This action cannot be undone",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(data, "delete dataaa")
                const stationaryId = data._id
                dispatch(DispatchService.deletestationary({ stationaryId })).then((response) => {
                    if (response.status === 200 || response.message === "stationary and related data deleted successfully") {
                        Swal.fire({
                            title: "Succesfully Deleted",
                            icon: "success",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setModal(false)
                                getdata()
                            }

                        })

                    } else {
                        // console.log('failed to delete 1')
                    }

                })
            } else {
                // console.log("failed to delete 2")
            }
        })
    }
    const columns = [
        {
            title: "S. No.",
            dataIndex: "S_No.",
            key: "index",
            render: (text, record, index) => index + 1,
        },
        {
            title: <span className="custom-title">Name</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.itemName}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">vendor Name</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.vendorName}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Quantity</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.quantity}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Remaining Quantity</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.remainingQuantity}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Allocate Stationary</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <IoIosAddCircleOutline onClick={() => handleAllocate(rec)} />
                </div>
            ),
        },
        {
            title: <span className="custom-title">Allocated User</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <PiUserList onClick={() => allocateStationaryData(rec)} />
                </div>
            ),
        },

        {
            title: <span className="custom-title">Action</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <Button className="DeleteButton" onClick={() => handleDelete(rec)}><MdDelete />Delete</Button>
                    <Button className="UpdateButton" onClick={() => handleUpdate(rec)} > <FaEdit />Edit</Button>
                </div>
            ),
        },

    ];


    const listColumn = [
        {
            title: "S. No.",
            dataIndex: "S_No.",
            key: "index",
            render: (text, record, index) => index + 1,
        },
        {
            title: <span className="custom-title">User Name</span>,
            dataIndex: "username",
            key: "username",
            render: (time, rec) => (
                <div
                    style={{
                        display: "flex",
                        marginLeft: "60px",
                    }}
                >
                    <Image
                        src={rec.userimage}
                        preview={false}
                        width={30}
                        height={30}
                        style={{
                            borderRadius: "20px",
                        }}
                    />
                    <span style={{ margin: "5px" }}>{rec.username}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Allocated Place</span>,
            dataIndex: "username",
            key: "username",
            render: (time, rec) => (
                <div className="timing">

                    <span>{rec.allocatedPlace}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Quantity</span>,
            dataIndex: "username",
            key: "username",
            render: (time, rec) => (
                <div className="timing">

                    <span>{rec.quantity}</span>
                </div>
            ),
        },
    ]

    function disabledDate(current) {
        // Disable all dates before today's date
        return current && current < moment().startOf("day");
    }
    return (
        <>
            <div>
                <Button type="primary" onClick={handleStationary}>Add Stationary</Button>
            </div>
            <Modal title="Stationary Detail" open={modal} onCancel={handleCancel} footer={null}>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[{ required: true, message: 'Please select the date!' }]}
                    >
                        <DatePicker disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item
                        label="Vendor Name"
                        name="vendorName"
                        
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Item Name"
                        name="itemName"
                        rules={[{ required: true, message: 'Please input the item name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Select Option" name="selectOption" rules={[{ required: true, message: 'Please input the quantity!' }]}>
                        <Select
                            style={{
                                width: 300,
                            }}
                            optionFilterProp="children"
                            showSearch
                            onSearch={onSearch}
                            filterOption={filterOption}
                            placeholder="Select option"
                            labelInValue={true}
                            options={category}
                            // dropdownRender={(menu) => <>{menu}</>}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.value}>
                                        {option.data.label}
                                    </span>
                                </Space>
                            )}
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[{ required: true, message: 'Please input the quantity!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">Add Stationary</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Card>
                {stationary.length > 0 ? (
                    <Table
                        // className="time-table"
                        columns={columns}
                        pagination={true}
                        dataSource={stationary}

                    />
                ): ""}  
            </Card>
            <Modal title="Allocate Stationary" open={allocateModal} onCancel={handleCancelAllocated} footer={null}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        label="Select Option"
                        name='username'
                        rules={[
                            {
                                required: true,
                                message: "Please select option!",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: 300,
                            }}
                            placeholder="Select option"
                            labelInValue={true}
                            // onChange={hideSelectedValues}
                            dropdownRender={(menu) => <>{menu}</>}
                        >
                            {items
                                .sort((a, b) => a.username.localeCompare(b.username))
                                .map((data, index) => (
                                    <>
                                        <Option
                                            key={index}
                                            value={data._id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Image
                                                preview={false}
                                                src={data.userimage}
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                            <span style={{ marginLeft: "18px" }}>
                                                {data.username}
                                            </span>
                                        </Option>
                                    </>
                                ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Quantity"
                        name="alocateStationaryQuantity"
                        rules={[{ required: true, message: 'Please input the quantity!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="option"
                        label="Option"
                        className="username"
                        rules={[
                            {
                                required: true,
                                message: "Please select option!",
                            },
                        ]}
                    >
                        <Select
                            defaultValue="Please Select"
                            style={{ width: 120 }}

                        >
                            {options.map((item) => (
                                <Option key={item.key} value={item.key}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Allocate</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Allocated User" open={listofUser} onCancel={cancle} footer={null} className="stationary-modal">
                {alotUser.length > 0 ? (
                    <Table
                        // className="time-table"
                        columns={listColumn}
                        pagination={true}
                        dataSource={alotUser}
                    />
                ): ""}
              
            </Modal>
            <Modal title="Update stationary store" open={updateModal} onCancel={handleUpdateCancel} footer={null}>
                <Form form={updateForm} layout="vertical" onFinish={handleUpdateSubmit}>
                  
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[{ required: true, message: 'Please select the date!' }]}
                    >
                        <DatePicker disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item
                        label="Vendor Name"
                        name="vendorName"

                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Item Name"
                        name="itemName"
                        rules={[{ required: true, message: 'Please input the item name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Select Option" name="selectOption" rules={[{ required: true, message: 'Please input the quantity!' }]}>
                        <Select
                            style={{
                                width: 300,
                            }}
                            optionFilterProp="children"
                            showSearch
                            onSearch={onSearch}
                            filterOption={filterOption}
                            placeholder="Select option"
                            labelInValue={true}
                            options={category}
                            // dropdownRender={(menu) => <>{menu}</>}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.value}>
                                        {option.data.label}
                                    </span>
                                </Space>
                            )}
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[{ required: true, message: 'Please input the quantity!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Update</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default Stationary