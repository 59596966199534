const initialState = {
  itemCart: null,
};

const itemReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_ITEM_CART":
        return {
       ...state,
          itemCart: action.payload,
        };
      default:
        return state;
    }
  };

export default itemReducer;