import React, { useEffect, useState } from "react";
import { Card, Image, Row, Col, Button, Space, Input, Pagination, Spin, Modal, Empty, Table, Rate } from "antd";
import Swal from "sweetalert2";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../styles/listTicket.css";
import * as FetchService from "../services/FetchService/FetchService";
import * as DispatchService from "../services/DispatchService/DispatchService";
import Blanklist from "../../src/assets/images/Blanklist.png";
import { find } from "lodash";
function ListTicket() {
  const [loader, setLoader] = useState(true);
  const [ticketData, setTicketData] = useState([]);
  const [openTicket, setOpenTicket] = useState(false);
  const [ratings, setRatings] = useState([]);
  const [ratingData, setRatingData] = useState([]);
  const [showSearchData, setShowSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [averageRating, setAverageRating] = useState(0)
  const [itemsPerPage] = useState(6);

  const { Search } = Input;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function handleDelete(item) {
    Swal.fire({
      title: "Are you sure you want to delete this item?",
      text: "This action cannot be undone",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Yes, delete it!"
        dispatch(DispatchService.DeleteFormTckt(item)).then((res) => {
          Swal.fire({
            title: "Successfully Deleted",
            icon: "success",
          });
          fetchData();
        });
      }
    });
  }
  const onSearch = (value) => {
    setLoader(true)
    const filterItems = ticketData.filter((searchdata) =>
      searchdata.name.toLowerCase().includes(value.toLowerCase())
    );
    if (filterItems === undefined || filterItems.length === 0) {
      Swal.fire({
        title: "There is no such food item",
        icon: "error",
      });
    } else {
      setShowSearchData(filterItems);
      setLoader(false)
    }
  };
  //To update a Product
  function updateProduct(item) {
    navigate("/update_ticket", { state: { data: item } });
  }
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    FetchList();
  };
  const FetchList = () => {
    dispatch(FetchService.FoodList())
      .then((res) => {
        setLoader(false);
        setTicketData(res.reverse());
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  };
  const totalItem = ticketData.length;
  // Calculate index of the last item based on current page and items per page
  const indexOfLastItem = currentPage * itemsPerPage;
  // Calculate index of the first item
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Get current items to display on the page
  const currentItems =
    showSearchData.length > 0
      ? showSearchData.slice(indexOfFirstItem, indexOfLastItem)
      : ticketData.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const options = (listofOption) => {
    if (listofOption?.option) {
      let optionlist = listofOption?.option.join(",");
      return optionlist;
    }
  };
  const itemlength = currentItems.length;
  const handleNewFood = () => {
    navigate("/form_ticket");
  };
  const handleOpen = (data) => {
    setOpenTicket(true)
    dispatch(DispatchService.getAllRatingData(data)).then((res) => {
      console.log(res, "resss")
      setRatingData(res)
    })
  }
  const closeModal = () => {
    setOpenTicket(false)
  }
  useEffect(() => {
    const totalRating = ratingData.reduce((sum, item) => sum + item.value, 0);
    const avgRating = totalRating / ratingData.length;
    setAverageRating(avgRating);
  }, [ratingData]);

  const columns = [
    {
      title: "S. No.",
      dataIndex: "S_No.",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      render: (text, record) => {
        return (
          <div key={record.id}>{record.username}</div>
        )
      }
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "name",
      render: (text, record) => <Rate value={record.value} disabled />
    },
  ]
  return (
    <>
      <div className="loaderOuter">
        {loader ? (
          <div className="loaderSpin">
            <Spin size="large" />
          </div>
        ) : (<>
          <Row>
            <Col span={6}>
              <Button type="primary" onClick={() => handleNewFood()}>
                Add New
              </Button>
            </Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
              <Space className="space">
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  enterButton
                  allowClear
                />
              </Space>
            </Col>
          </Row>
          <Row>
            {currentItems.map((data, index) => (
              <Col md={12} lg={8} sm={24} key={index}>
                <Card className="cardbdy">
                  <div className="imgdiv">
                    <Image
                      className="CardImage"
                      src={`https://foodorde-api.aleaspeaks.com/${data.path}`}
                      style={{ height: "110vh", maxHeight: "200px" }}
                    />
                  </div>
                  <div className="ListticketContent">
                    <div className="heading">
                      <h1>{data.name}</h1>
                    </div>
                    <p>
                      <ClockCircleOutlined
                        style={{ marginRight: "10px", fontSize: "17px" }}
                      />
                      {/* {data.option} */}
                      {options(data)}
                    </p>
                    <div className="btnwrapper">
                      <Button
                        type="danger"
                        className="btn"
                        style={{ backgroundColor: "red", color: "white" }}
                        onClick={() => handleDelete(data)}
                      >
                        Delete
                      </Button>
                      <Button
                        type="primary"
                        className="btn"
                        onClick={() => updateProduct(data)}
                      >
                        Update
                      </Button>

                    </div>
                    <Button onClick={() => handleOpen(data)}> See Rating</Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          {currentItems.length !== 0 && currentItems !== undefined ? (
            <>
              <Row>
                <Col span={6}>
                  <h4>
                    {/* Total Food :- {itemlength} */}
                    Showing {itemlength} of {totalItem} entries
                  </h4>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Pagination
                    className="pagination"
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={
                      showSearchData.length > 0
                        ? showSearchData.length
                        : ticketData.length
                    }
                    onChange={handlePageChange}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col span={24} className="ViewCartImage ">
                <div className="blanklist ">
                  <Image src={Blanklist} preview={false} />
                </div>
              </Col>
            </Row>
          )}
        </>)}


        <Modal
          open={openTicket}
          onOk={closeModal}
          onCancel={closeModal}
          footer={null}
          className="user-modal"
        >

          <Card>
            {ratingData.length !== 0 ? (
              <>
                <Table columns={columns}
                  dataSource={ratingData} />

                <div style={{ marginTop: 20 }}>
                  <span>Average Rating: </span>
                  <Rate value={averageRating} disabled allowHalf />
                </div>
              </>
            ) : (<Empty />)}
          </Card>
        </Modal>
      </div>
    </>
  );
}
export default ListTicket;
