import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Select, TimePicker, Table, Spin, Card } from "antd";
import * as FetchService from "../services/FetchService/FetchService";
import * as DispatchService from "../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import "../styles/timelist.css";

const Timelist = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectStartTime, setSelectStartTime] = useState(null);
  const [selectEndTime, setSelectEndTime] = useState(null);
  const [timeList, setTimeList] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [updateStartTime, setUpdateStartTime] = useState(null);
  const [updateEndTime, setUpdateEndTime] = useState(null);
  const [loader, setLoader] = useState(false);
  const [getID, setGetId] = useState();
  const [form] = Form.useForm();
  const [UpdateForm] = Form.useForm();
  const dispatch = useDispatch();
  const slotOptions = ["Morning", "Afternoon", "Evening", "AllTime"];
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleUpdatedOk = () => {
    setEditOpen(false);
  };
  const handleUpdateCancel = () => {
    setEditOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    let data = {
      values: values.options,
      start_time: selectStartTime,
      end_time: selectEndTime,
    };
    dispatch(DispatchService.CreateTime(data));

    setIsModalOpen(false);
    fetchTime();
  };
  useEffect(() => {
    fetchTime();
  }, []);
  const fetchTime = () => {
    setLoader(true)
    dispatch(FetchService.TimeList()).then((res) => {
      setTimeList(res);
      setLoader(false)
    });
  };
  const onStartChange = (time, timeString) => {
    setSelectStartTime(timeString, time);
  };
  const onEndChange = (time, timeString) => {
    setSelectEndTime(timeString, time);
  };
  const handleEditButton = (time) => {
    setGetId(time._id);
    setEditOpen(true);
  };
  const onUpdateFinish = (UpdateValue) => {
    let updatedData = {
      values: UpdateValue?.options,
      start_time: updateStartTime,
      end_time: updateEndTime,
      _id: getID,
    };
    dispatch(DispatchService.UpdateFoodTime(updatedData)).then(
      (response) => { }
    );
    setEditOpen(false);
    fetchTime();
  };
  const onUpdateEnd = (time, timeString) => {
    setUpdateEndTime(timeString, time);
  };
  const onUpdateStart = (time, timeString) => {
    setUpdateStartTime(timeString, time);
  };
  const filteredOptions = slotOptions.filter((slot) => {
    return !timeList.find((time) => time.option === slot);
  });
  const columns = [
    {
      title: <span className="time-title"> Option</span>,
      dataIndex: "option",
      key: "option",
      className: "Option-Name",
    },
    {
      title: <span className="custom-title">Timing</span>,
      dataIndex: "",
      key: "timing",
      render: (time, rec) => (
        <div className="timing">
          <span>{rec.start_time}</span>
          <span>-</span>
          <span>{rec.end_time}</span>
        </div>
      ),
    },
    {
      title: <span className="custom-title">Action</span>,
      dataIndex: "",
      key: "action",
      render: (editTime, i) => (
        <div className="editbtn-wrapper">
          <Button
            className="edit-wrapper"
            onClick={() => handleEditButton(editTime)}
          >
            <EditOutlined className="edit-icon" />
          </Button>
        </div>
      ),
    },
  ];
  const checkDisabledButton = () => {
    if (filteredOptions?.length > 0) {
      return false;
    } else {
      return true;
    }
  };



  // const handleChecking = () => {
  //   dispatch(DispatchService.Checking(timeList)).then((response) => {
  //     console.log(response, "response")
  //   })
  // }
  // const handleRefillChecking = () => {
  //   dispatch(DispatchService.RefillChecking(timeList)).then((response) => {
  //     console.log(response, "response")
  //   })
  //  }
  return (
    <>
      <div className="loaderOuter">
        {
          loader ? (
            <div className="loaderSpin">
              <Spin size="large" />
            </div >
          ) : (<>

            <Button
              type="primary"
              onClick={showModal}
              className="Add-time-btn"
              disabled={checkDisabledButton()}
            >
              Add Timing
            </Button>
           
            <Modal
              title="Select Time"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <Form className="MainFormTime" onFinish={onFinish} form={form}>
                <Form.Item label="Options" name="options" className="dropdown-item">
                  <Select
                    style={{
                      width: 300,
                    }}
                    placeholder="Select option"
                  >
                    {filteredOptions.map((slotOptions, index) => (
                      <Select.Option key={index} value={slotOptions}>
                        {slotOptions}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Start Time" name="start_time">
                  <TimePicker
                    onChange={onStartChange}
                    format="HH:mm"
                    className="formTiming"
                  />
                </Form.Item>
                <Form.Item label="End Time" name="end_time">
                  <TimePicker
                    onChange={onEndChange}
                    format="HH:mm"
                    className="formTiming"
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="update-btn" type="primary">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            {timeList.length > 0 ? (
              <Card className="timelistCard">
                <Table
                  className="time-table"
                  columns={columns}
                  pagination={false}
                  dataSource={timeList}
                  loading={loader}
                />
              </Card>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "150px",
                }}
              >
                No timing is added{" "}
              </p>
            )}
            <Modal
              title="Select Updated Time"
              visible={editOpen}
              onOk={handleUpdatedOk}
              onCancel={handleUpdateCancel}
              footer={null}
            >
              <Form
                className="MainFormTime"
                onFinish={onUpdateFinish}
                form={UpdateForm}
              >
                <Form.Item label="Start Time" name="start_time">
                  <TimePicker
                    onChange={onUpdateStart}
                    format="HH:mm"
                    className="formTiming"
                  />
                </Form.Item>
                <Form.Item label="End Time" name="end_time">
                  <TimePicker
                    onChange={onUpdateEnd}
                    format="HH:mm"
                    className="formTiming"
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="update-btn" type="primary">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>



          </>)}
      </div>
    </>
  );
};
export default Timelist;
