import React, { useEffect, useState } from 'react'
import { Form, Select, Image, Space, Button, Modal, Table, DatePicker } from 'antd'
import { useDispatch } from 'react-redux';
import * as FetchService from "../services/FetchService/FetchService";
import * as DispatchService from "../services/DispatchService/DispatchService";
import "../../src/styles/settings.css";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Swal from 'sweetalert2';
import * as XLSX from "xlsx";
import moment from 'moment';
const Settings = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [items, setItems] = useState([]);
    const [initialValues, setInitialValues] = useState({ category: '', username: '' });
    const [emails, setEmails] = useState([])
    const [userFooddata, setUserFooddata] = useState([])
    const [updateId, setUpdateId] = useState('')
    const [modal, setModal] = useState(false)
    const [updateModel, setUpdateModel] = useState(false)
    const onSearch = (value) => {
        // console.log('search:', value);
    };
    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    
    useEffect(() => {
        UserDetails()
        handleEmail()
    }, [])

    const UserDetails = () => {
        dispatch(FetchService.UserDetail())
            .then((response) => {
                const transformedOptions = response.map((item) => ({
                    label: item.username,
                    value: item._id,
                    image: item.userimage
                }));
                setItems(transformedOptions)

            })
    }
    const category = [
        {
            value: "Hardware",
            label: "Hardware",
        },
        {
            value: "Stationary",
            label: "Stationary",
        },
    ];
    const handleEmail = () => {
        dispatch(FetchService.getEmail())
            .then((response) => {
                setEmails(response)
            })
    }
    const onFinish = (values) => {
        const user_ids = values.username.map(item => item.value);
        const data = {
            user_id: user_ids,
            category: values.category
        }
        dispatch(DispatchService.email(data))
            .then((response) => {
                if (response) {
                    setModal(false)
                    handleEmail()
                }
                form.resetFields()
                // console.log(response.data , "data")
            })
    }
    const handleRequirment = () => {
        setModal(true)
    }
    const handleCancel = () => {
        setModal(false)
    }
    const handleDelete = (data) => {
        Swal.fire({
            title: "Are you sure you want to delete this item?",
            text: "This action cannot be undone",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                const EmailID = data._id
                dispatch(DispatchService.DeleteEmail({ EmailID })).then((response) => {
                    if (response.status === 200 || response.message === "Email deleted successfully.") {
                        Swal.fire({
                            title: "Succesfully Deleted",
                            icon: "success",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                handleEmail()
                            }
                        })
                    } else {
                        // console.log('failed to delete 1')
                    }
                })
            } else {
                // console.log("failed to delete 2")
            }
        })

    }
    const handleUpdateCancel = () => {
        setUpdateModel(false)
        setInitialValues({ category: '', username: '' })
    }
    const handleUpdateSubmit = (values) => {
        const category = values.category
        const user_ids = values.username.value
        const data = {
            category,
            user_ids,
            updateId
        }
        dispatch(DispatchService.updateEmail({ data })).then((response) => {
            if (response.message === "Emails  Updated successfully") {
                Swal.fire({
                    title: "Succesfully Updated",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUpdateModel(false)
                        setInitialValues({ category: '', username: '' })
                        handleEmail()
                    }
                })
            }
        })

    }
    const handleUpdate = (data) => {

        setUpdateModel(true)
        setUpdateId(data._id)

        const intinaldata = ({
            category: data.category,
            username: data.username
        });

        updateForm.setFieldsValue(intinaldata);
    }
    const listColumn = [
        {
            title: "S. No.",
            dataIndex: "S_No.",
            key: "S_No",
            render: (text, record, index) => index + 1,
        },
        {
            title: <span className="custom-title">Category  </span>,
            dataIndex: "category",
            key: "category",
        },
        {
            title: <span className="custom-title">Email  </span>,
            dataIndex: "email",
            key: "email",
        },
        {
            title: <span className="custom-title">Action</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <Button className="DeleteButton" onClick={() => handleDelete(rec)}><MdDelete />Delete</Button>
                    <Button className="UpdateButton" onClick={() => handleUpdate(rec)} > <FaEdit />Edit</Button>
                </div>
            ),
        },
    ];


    const handleSlackMsg = () => {
        dispatch(DispatchService.slackMsg())
            .then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        title: "Succesfully Sent",
                        icon: "success",
                    });

                }
            })
    }
    useEffect(() => {
        dispatch(DispatchService.UserFoodData())
            .then((response) => {

                const finalData = response.finalDataArray.map((item) => ({
                    UserName: item.user_Name,
                    FoodName: item.food_Name,
                    TokenNo: item.token_no,
                    createdAt: item.createdAt,
                    updatedAt: item.updatedAt,
                }));

                setUserFooddata(finalData)
            })
    }, [])
    const handleExcelData = () => {
        if (!selectedMonth) {
            // Show an error message if no month is selected
            Swal.fire({
                title: "Please Select month",
                icon: "error",
            });
            return;
        }
        const filteredData = userFooddata.filter(
            (item) => moment(item.createdAt).month() === selectedMonth.month());
        const filteredUpdatedData = userFooddata.filter(
            (item) => moment(item.updatedAt).month() === selectedMonth.month());

        if (filteredData || filteredUpdatedData) {
            const filteredDataWithoutCreatedAt = filteredData.map(({ createdAt, updatedAt, ...rest }) => rest);
            const filteredDataWithoutUpdatedAt = filteredUpdatedData.map(({ createdAt, updatedAt, ...rest }) => rest);
            const mergedFilteredData = [...filteredDataWithoutCreatedAt, ...filteredDataWithoutUpdatedAt];
            const worksheet = XLSX.utils.json_to_sheet(mergedFilteredData);
            const workbook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(
                workbook,
                worksheet,
                `Data_${selectedMonth.format("YYYY-MM")}`
            );
            XLSX.writeFile(workbook, `data_${selectedMonth.format("YYYY-MM")}.xlsx`);
        }


    }
    const handleDatePickerChange = (date, dateString) => {
        setSelectedMonth(moment(dateString));
    };
    return (
        <>
            <div className='settingWrapper'>
                <div style={{ padding: "0 1rem" }}>
                    <Button type="primary" onClick={handleRequirment}>Add emails</Button>
                </div>
                <div className='slackMsg'>
                    <Button type="primary" onClick={handleSlackMsg}> Send Slack Message </Button>
                </div>
                <div >
                    <DatePicker picker="month" onChange={handleDatePickerChange} className='datePicker' />
                    <Button type="primary" onClick={handleExcelData}> Export  assign  data </Button>
                </div>
                <Modal title="Requisition Form" open={modal} onCancel={handleCancel} footer={null}>

                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            label="Select User"
                            name='username'
                            rules={[
                                {
                                    required: true,
                                    message: "Please select option!",
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: 300,
                                }}
                                mode="multiple"
                                allowClear

                                optionFilterProp="children"
                                showSearch
                                onSearch={onSearch}
                                filterOption={filterOption}
                                placeholder="Select option"
                                labelInValue={true}
                                options={items}

                                // dropdownRender={(menu) => <>{menu}</>}
                                optionRender={(option) => (
                                    <Space>
                                        <Image src={option.data.image} preview={false}

                                            style={{
                                                width: "25px",
                                                height: "25px",
                                                borderRadius: "50%",
                                            }} />
                                        <span role="img" aria-label={option.data.value}>
                                            {option.data.label}

                                        </span>
                                    </Space>
                                )}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name="category"
                            label="Category"
                            className="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select category!",
                                },
                            ]}
                        >
                            <Select

                                allowClear
                                className="inFraSelect"
                                placeholder="Please select category"
                                optionLabelProp="label"
                                options={category}
                                optionrender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.value}>
                                            {option.data.label}

                                        </span>

                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">save</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <div className='tableWrapper'>
                    {emails.length > 0 ? (
                        <Table
                            className="time-table"
                            columns={listColumn}
                            pagination={true}
                            dataSource={emails}
                        />
                    ): ""}
                   
                </div>

                <Modal title=" Update Category" open={updateModel} onCancel={handleUpdateCancel} footer={null}>
                    <Form form={updateForm} layout="vertical" onFinish={handleUpdateSubmit}>
                        <Form.Item
                            label="Select User"
                            name='username'
                            rules={[
                                {
                                    required: true,
                                    message: "Please select option!",
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: 300,
                                }}

                                allowClear

                                optionFilterProp="children"
                                showSearch
                                onSearch={onSearch}
                                filterOption={filterOption}
                                placeholder="Select option"
                                labelInValue={true}
                                options={items}
                                // dropdownRender={(menu) => <>{menu}</>}
                                optionRender={(option) => (
                                    <Space>
                                        <Image src={option.data.image} preview={false}
                                            style={{
                                                width: "25px",
                                                height: "25px",
                                                borderRadius: "50%",
                                            }} />
                                        <span role="img" aria-label={option.data.value}>
                                            {option.data.label}

                                        </span>
                                    </Space>
                                )}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name="category"
                            label="Category"
                            className="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select category!",
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                className="inFraSelect"
                                // style={{
                                //     width: "100%",
                                // }}
                                placeholder="Please select category"
                                optionLabelProp="label"
                                options={category}
                                optionrender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.value}>
                                            {option.data.label}

                                        </span>

                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Update</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </>
    )
}
export default Settings