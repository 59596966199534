import React, { useState, useEffect } from "react";
import { Card, Row, Col, Empty, Select, Spin, message } from "antd";
import "../../styles/userpage.css";
import * as FetchService from "../../services/FetchService/FetchService";
import * as DispatchService from "../../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setUserDetail } from "../../store/actions/index";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import {
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { setAddItemCart } from "../../store/actions/index";

const { Option } = Select;
const { Meta } = Card;

const Userpage = () => {
  const [foodlist, setFoodlist] = useState([]);
  const [counts, setCounts] = useState({});
  const [itemCart, setItemCart] = useState([]);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState([]);
  const [addCart, setAddCart] = useState([]);
  const [loader, setLoader] = useState(true);
  const allItems = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [filteredFoodResult, setFilteredFoodResult] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  dispatch(setUserDetail(location?.state?.userDetail));
  const loggedInUserRole = useSelector((state) => state.user.userID);
  let getuserid = loggedInUserRole;
  useEffect(() => {
    fetchUserData();
  }, [getuserid]);

  const fetchUserData = () => {
    if (getuserid) {
      try {
        dispatch(DispatchService.UserOrderList({ getuserid })).then(
          (response) => {
            if (response) {
              setFoodlist(response);
            }
            // Initialize counts state with default value of 0 for each food item
            const initialCounts = {};
            if (response.length > 0) {
              response?.forEach((food) => {
                initialCounts[food._id] = 0;
              });
            }
            setCounts(initialCounts);
            dispatch(FetchService.TimeList()).then((res) => {
              setTimeList(res);
              fetchData();
              setLoader(false);
            });
          }
        );
      } catch (error) {
        console.error("Error fetching food list:", error);
      }
    }
  };
  const fetchData = () => {
    dispatch(DispatchService.ShoppingCart({ getuserid })).then((response) => {
      setAddCart(response);
      dispatch(setAddItemCart(response));
      setLoader(false);
    });
  };
  const currentTime = new Date();
  const currentEndTime = new Date();
  const checkDateAndTime = new Date();
  const currentMinimumStartTime = new Date();
  const filterFoodByTiming = () => {
    if (foodlist) {
      const filteredFood = foodlist.filter((food) => {
        const minimumTime = food.minimumStartTime;
        if (minimumTime) {
          const [minimumHRs, minimumMinute] = minimumTime
            .split(":")
            .map(Number);
          currentMinimumStartTime.setHours(minimumHRs, minimumMinute, 0, 0);
        } else {
          // console.log("this is not matching");
        }
        const currentOption = food.option;
        const matchingTiming = timeList.find((timing) => {
          let val = timing.option;
          for (let i = 0; i < currentOption.length; i++) {
            if (currentOption[i] === val) {
              let startTime = timing.start_time;
              let endTime = timing.end_time;
              const [hours, minutes] = startTime.split(":").map(Number);
              const [hrs, mints] = endTime.split(":").map(Number);
              currentTime.setHours(hours, minutes, 0, 0);
              currentEndTime.setHours(hrs, mints, 0, 0);
              if (
                checkDateAndTime >= currentTime &&
                checkDateAndTime <= currentEndTime &&
                checkDateAndTime >= currentMinimumStartTime
              ) {
                return foodlist;
              } else {
              }
            }
          }
        });
        return matchingTiming;
      });
      return filteredFood;
    }
  };
  useEffect(() => {
    const filteredFood = filterFoodByTiming();
    setFilteredFoodResult(filteredFood);
    let timer = setInterval(() => {
      fetchUserData();
    }, 60000);
    return () => clearInterval(timer);
  }, [timeList]);
  const decrement = (fooditemId) => {
    let ItemFoodID = fooditemId._id;
    let foodId = fooditemId.food_id;
    let findItem = Object.keys(counts).find((item) => item === ItemFoodID);
    if (findItem !== undefined) {
      let findInCart = addCart?.find((item) => item.food_id === foodId);
      if (findInCart !== undefined) {
        dispatch(DispatchService.RemoveItem({ foodId })).then((response) => {
          fetchData();
          messageApi.open({
            type: 'success',
            content: 'Item remove from cart',
          });
          if (response !== null) {
            setQuantity(response);
          } else {
            console.info("deleted");
          }
        });
      } else {
        messageApi.open({
          type: 'warning',
          content: 'You have not add anything'
        });
      }
    } else {
      console.info("deleted");
    }
  };
  let alldata = addCart;
  const additemcart = useSelector((state) => state.item.itemCart);
  const increment = (food) => {
    console.log("hello")
    let foodName = selectedOptions;
    let foodId = food.food_id;
    const findInCart = additemcart.find((item) => item.food_id === foodId);
    if (food.premix) {
      food.name = foodName;
    }
    if (food.premix && !selectedOptions) {
      Swal.fire({
        title: "Please  Select any option",
        icon: "warning",
      });
      return;
    }
    if (findInCart !== undefined) {
      if (findInCart && findInCart.remaining_token > 0) {
        if (counts[food._id] < findInCart.token_no) {
          setCounts((prevCounts) => ({
            ...prevCounts,
            [food._id]: prevCounts[food._id] + 1,
          }));
          setItemCart([...itemCart, food]);
          food.count = 1;
          if (counts[food._id] + 1 === findInCart.remaining_token) {
            food.disabled = true;
          }
          dispatch(DispatchService.AddItem(food)).then((response) => {
            fetchData();
            if (
              response.message === "added" ||
              response.message === "updated"
            ) {
              message.success('Successfully added to the cart')
            }
          });
        } else {
        }
      } else {
        messageApi.open({
          type: 'warning',
          content: 'You have exceeded the limit',
        });
      }
    } else if (counts[food._id] < food.remainingToken_no) {
      setCounts((prevCounts) => ({
        ...prevCounts,
        [food._id]: prevCounts[food._id] + 1,
      }));
      setItemCart([...itemCart, food]);
      food.count = 1;
      if (counts[food._id] + 1 === food.remainingToken_no) {
        food.disabled = true;
      }
      dispatch(DispatchService.AddItem(food)).then((response) => {
        fetchData();
        if (response.message === "added" || response.message === "updated") {
          messageApi.open({
            type: 'success',
            content: 'Successfully added to the cart',
          });
        }
      });
    } else {
      messageApi.open({
        type: 'warning',
        content: 'You have exceeded the limit',
      });
    }
  };
  const getTokenCount = (food) => {
  
    const findItem = addCart?.find((item) => {
      return item.food_id === food.food_id;
    });
    if (findItem && findItem !== undefined) {
      return findItem.quantity;
    }

    // console.log(
    //   getTokenCount(food) !== undefined
    //     ? getTokenCount(food)
    //     : 0
    // )
    
    
  };
  function shoppingcart() {
    navigate("/shopping_cart", { state: { data: alldata } });
    setLoader(false)
  }
  const checkRemainingToken = (data) => {
    let TotalTokens = data.food_id;
    const findRemainingTokens = foodlist?.find((item) => {
      return item.food_id === TotalTokens;
    });
    if (findRemainingTokens !== undefined) {
      return findRemainingTokens.remainingToken_no;
    }
  };
  const checkFoodName = (data) => {
    let TotalTokens = data.food_id;
    const findRemainingTokens = allItems?.find((item) => {
      return item.food_id === TotalTokens;
    });
    if (findRemainingTokens !== undefined) {
      return findRemainingTokens.name;
    }
  };
  const options = (listofOption) => {
    if (listofOption?.option) {
      let optionlist = listofOption?.option.join(",");
      return optionlist;
    }
  };
  const handleOptionChange = (value) => {
    setSelectedOptions(value);
  };
  return (
    <>
      {contextHolder}
      <div className="loaderOuter">
        {loader ? (
          <div className="loaderSpin" >
            <Spin size="large" />
          </div >
        ) : (
          <>
            <div className="cart-wrapper">
              <ShoppingCartOutlined className="cart" onClick={() => shoppingcart()} />
              <p className="items-num"> {addCart.length}</p>
            </div>
            <Row gutter={[16, 16]} className="row">
              {filteredFoodResult &&
                filteredFoodResult.length > 0 &&
                Array.isArray(filteredFoodResult) ? (
                filteredFoodResult.map((food, index) => (
                  <Col md={12} lg={8} xs={24} key={index} className="column">
                    <Card
                      hoverable
                      cover={
                        <img
                          alt="example"
                          src={`https://foodorde-api.aleaspeaks.com/${food.path}`}
                        />
                      }

                      
                      actions={[
                        <div
                          className={`tokenbtn ${food.status === 0 ? "disabled-food" : ""
                            }`}
                        >
                          <MinusOutlined
                            className="in-btn"
                            onClick={() => decrement(food)}
                            disabled={food.status === 0}
                          />
                          <p>
                            {getTokenCount(food) !== undefined
                              ? getTokenCount(food)
                              : 0}
                          </p>
                          <PlusOutlined
                            className="in-btn"
                            onClick={() => increment(food)}
                            disabled={food.status === 0}
                          />
                          {food.status === 0 && (
                            <span className="disabled-message">
                              Currently Not Available
                            </span>
                          )}

                          {food.premix === true && (
                            <Select
                              defaultValue="Please Select"
                              onChange={(value) => handleOptionChange(value)}
                            >
                              {food.name.split(",").map((item, index) => (
                                <Option key={index} value={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          )}
                         
                        </div>
                      ]}
                      
                      className="foodCard"
                    >
                      <Meta
                        title={checkFoodName(food) ? checkFoodName(food) : food.name}
                        description={
                          <div className="description">
                            <p className="descList">
                              <span key="token"><strong>Total Token: </strong> {food.token_no}</span>
                            </p>
                            <p className="descList">
                              <span key="token"><strong>Remaining Token: </strong>
                                {checkRemainingToken(food) ||
                                  checkRemainingToken(food) === 0
                                  ? checkRemainingToken(food)
                                  : food.remainingToken_no
                                }
                              </span>
                            </p>
                            <p className="descList">
                              <span key="token"><strong>Order Timing:</strong> {options(food)}</span>
                            </p>
                          </div>

                        }

                      />

                    </Card>

                  </Col>
                ))
              ) : (

                <Col lg={24}>
                  <Empty
                    description={<p>No coupons have been allocated at this time.</p>}
                  />
                </Col>

              )}
            </Row>
          </>)
        }
      </div>
    </>
  );
};
export default Userpage;
