import React, { useState, useEffect } from "react";
import { Card, Row, Col, Empty, Flex, Rate, Spin } from "antd";
import "../../styles/userpage.css";
import * as DispatchService from "../../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "../../styles/AllAssignTckt.css";
import Swal from "sweetalert2";
const desc = ["terrible", "bad", "normal", "good", "wonderful"];
const { Meta } = Card;
const AssginedTicket = () => {
    const dispatch = useDispatch();
    const loggedInUserRole = useSelector((state) => state.user.userID);
    const [assignTicket, setAssignTicket] = useState([]);
    const [addCart, setAddCart] = useState([]);
    const [loader, setLoader] = useState(true);
    const [allRatingData, setAllRatingData] = useState({});
    const userData = loggedInUserRole;
    let getuserid = userData;
    useEffect(() => {
        if (getuserid) {
            try {
                dispatch(DispatchService.UserOrderList({ getuserid })).then(
                    (response) => {
                        setAssignTicket(response);
                        setLoader(false)
                    }
                );
            } catch (error) {
                console.error("Error fetching food list:", error);
            }
        }
    }, [getuserid]);


    useEffect(() => {
        fetchData();
        dispatch(DispatchService.getRating(getuserid))
            .then((response) => {
                setAllRatingData(response);
                setLoader(false)
            })
            .catch((err) => {
                console.log({ err });
            });
    }, []);
    //................................................. shopping cart api......................................//
    const fetchData = () => {
        dispatch(DispatchService.ShoppingCart()).then((response) => {
            setAddCart(response);
            setLoader(false)
        });
    };
    const checkFoodName = (data) => {
        let TotalTokens = data.food_id;
        const findRemainingTokens = assignTicket?.find((item) => {
            return item.food_id === TotalTokens;
        });
        if (findRemainingTokens !== undefined) {
            return findRemainingTokens.name;
        }
    };
    const options = (listofOption) => {
        let optionlist = listofOption?.option?.join(",");
        return optionlist;
    };
    //..................rating...................//
    const handleRating = (rating, foodId) => {
        const ratedFood = assignTicket.find((food) => food.food_id === foodId);
        const ratingDatadetail = desc[rating - 1];
        if (ratedFood) {
            const ratingData = {
                ...allRatingData,
                foodName: ratedFood.name,
                rating: ratingDatadetail,
                userId: ratedFood.user_id,
                foodId: ratedFood.food_id,
                value: rating,
            };
            dispatch(DispatchService.Rating(ratingData))
                .then((response) => {

                    setAllRatingData(response)
                    Swal.fire({
                        title: "Thanks for giving rating",
                        icon: "success",
                    })
                })
                .catch((error) => {
                    console.error("Error adding item to cart:", error);
                });
        } else {
            // console.log("Food not found");
        }
    };
    const checkRating = (item) => {
        if (allRatingData?.length > 0) {
            let rating;
            allRatingData?.find((record) => {
                if (record.food_id === item.name) {
                    rating = record.value;
                }
                return rating;
            });
            return rating;
        } else {
            return 0;
        }
    };
    return (
        <div className="loaderOuter">
            {
                loader ? (
                    <div className="loaderSpin">
                        <Spin size="large" />
                    </div >
                ) : (<>
                    <Row gutter={[16, 16]}>
                        {assignTicket &&
                            assignTicket.length > 0 &&
                            Array.isArray(assignTicket) ? (
                            assignTicket.map((food, index) => (
                                <Col md={12} lg={8} xs={24} key={index}>
                                    <Card
                                        hoverable
                                        cover={
                                            <img
                                                alt="example"
                                                src={`https://foodorde-api.aleaspeaks.com/${food.path}`}
                                            />
                                        }
                                        actions={[
                                            <span key="token"><strong>Total Token:</strong> {food.token_no}</span>,
                                            <span key="token"><strong>Remaining Token:</strong> {food.remainingToken_no}</span>,

                                            <span key="token"><strong>Order Timing:</strong> {options(food)}</span>,
                                        ]}
                                        className="foodCard"
                                    >
                                        {/* <Meta
                                            title={checkFoodName(food) ? checkFoodName(food) : food.name}
                                            description={
                                                <div className="rating">
                                                    <Flex gap="middle" vertical>
                                                        <Rate
                                                            tooltips={desc}
                                                            onChange={(value) =>
                                                                handleRating(value, food.food_id)
                                                            }
                                                            value={checkRating(food)}
                                                        />
                                                        {allRatingData[food.food_id]?.rating && (
                                                            <span className="rating">
                                                                {allRatingData[food.food_id].rating}
                                                            </span>
                                                        )}
                                                    </Flex>
                                                </div>
                                            }
                                        /> */}
                                    </Card>
                                </Col>
                            ))
                        ) : (
                            <Col lg={24}>
                                <Empty
                                    description={<p>No coupons Assign</p>}
                                />
                            </Col>

                        )}
                    </Row>
                </>)
            }
        </div>
    );
};
export default AssginedTicket;
