
let fetchDetail_Url;
// const fetchDetail_Url = "http://localhost:8000/";

var currentUrl = window.location.href;

// Check if "localhost" is present in the URL
if (currentUrl.indexOf("localhost") !== -1) {
    fetchDetail_Url = "http://localhost:8000/";
 
} else {
    // "localhost" is not present in the URL
    fetchDetail_Url = "https://foodorde-api.aleaspeaks.com/";
}

export  {fetchDetail_Url};
