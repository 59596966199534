import React, { useState, useEffect } from "react";
import {
  Table,
  Image,
  Checkbox,
  Button,
  Rate,
  Row,
  Col,
  DatePicker,
  Spin,
  Card
} from "antd";
import { useDispatch } from "react-redux";
import * as FetchService from "../services/FetchService/FetchService";
import orderhistroy from "../assets/images/orderhistroy.jpg";
import * as DispatchService from "../services/DispatchService/DispatchService";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import moment from "moment";
import "../styles/orderlist.css";
import { DeleteOutlined } from "@ant-design/icons";
const Orderlist = () => {
  const [orderplaced, setOrderplaced] = useState([]);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [uniqueFoodNames, setUniqueFoodNames] = useState([]);
  const [addData, setAddData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [messageDeliveredData, setMessageDeliveredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    orderlist();
  }, []);
  const orderlist = () => {
    setLoader(true)
    dispatch(FetchService.OrderList())
      .then((response) => {
        let filterRecords = response?.filter((item) => {
          return item !== null;
        });
        setOrderplaced(filterRecords);
        setLoader(false)
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const formatDate = (dateTime) => {
    const timeDate = new Date(dateTime);
    return timeDate.toLocaleString();
  };
  useEffect(() => {
    if (orderplaced !== undefined && orderplaced !== null) {
      let uniqueRecords = Array.from(
        new Set(orderplaced.map((item) => item.user_id))
      ).map((id) => orderplaced.find((record) => record.user_id === id));
      setUniqueUsers(uniqueRecords);
    }
  }, [orderplaced]);

  useEffect(() => {
    const uniqueFoodNames = orderplaced.map((name) => name.name);
    setUniqueFoodNames(uniqueFoodNames);
  }, [orderplaced]);

  const handleCheckBox = (record) => {
    const isorderisChecked = addData.some((item) => item._id === record._id);
    if (isorderisChecked) {
      const updatedData = addData.filter((item) => item._id !== record._id);
      setAddData(updatedData);
    } else {
      setAddData((prevAddData) => [...prevAddData, record]);
    }
  };

  const handleCheckBoxData = () => {
    dispatch(DispatchService.Checkbox(addData))
      .then(() => {
        setMessageDeliveredData((prevData) => [
          ...prevData,
          ...addData.map(item => ({ checkbox_id: item._id })),
        ]);
        setAddData([]);

      })
      .catch((error) => {
        console.error("Error adding item to cart:", error);
      });
  };

  useEffect(() => {
    dispatch(FetchService.getallCheckboxData()).then((response) => {
      setMessageDeliveredData(response);
    });
  }, []);

  const ischecked = addData.length === 0;
  const checkSelectedRecords = (value) => {
    let findChecked = addData?.find((item) => {
      return (
        item.user_id === value.user_id &&
        value.food_id === item.food_id &&
        value._id === item._id
      );
    });
    if (findChecked !== undefined) {
      return true;
    } else {
      let findChecked = messageDeliveredData?.find((item) => {
        return item.checkbox_id === value._id;
      });
      if (findChecked !== undefined) {
        return true;
      } else {
        return false;
      }
    }
  };
  const checkDisabledRecords = (value) => {
    let findChecked = messageDeliveredData?.find((item) => {
      return item.checkbox_id === value._id;
    });
    if (findChecked !== undefined) {
      return true;
    } else {
      return false;
    }
  };
  const handleCancel = (data) => {
    Swal.fire({
      title: "Are you sure you want to delete this item?",
      text: "This action cannot be undone",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DispatchService.CancelOrders(data))
          .then((response) => {
            if (response) {
              Swal.fire({
                title: "Successfully Deleted",
                icon: "success",
              });
              orderlist();
            }
          })
      }
    })
  }
  const columns = [
    {
      title: "S. No.",
      dataIndex: "S_No.",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: <span className="custom-title">Food Name</span>,
      dataIndex: "foodname",
      className: "custom-data",
      render: (txt, img) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={`https://foodorde-api.aleaspeaks.com/${img.foodimage}`}
              preview={false}
              width={35}
              height={35}
              style={{
                borderRadius: "20px",
              }}
            />
            <span style={{ textAlign: "center", margin: "7px" }}>
              {img.name}
            </span>
          </div>
        );
      },
      filters: uniqueFoodNames.map((item) => ({
        text: <span>{item}</span>,
        value: item,
      })),
      onFilter: (value, record) => record.name.indexOf(value) === 0,
    },
    {
      title: <span className="custom-title">Username</span>,
      dataIndex: "username",
      className: "custom-data",
      render: (text, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={record.userimage}
              preview={false}
              width={35}
              height={35}
              style={{
                borderRadius: "20px",
              }}
            />
            <span className="custom-name">{record.username}</span>
          </div>
        );
      },
      filters: uniqueUsers.map((records) => ({
        text: records.username,
        value: records.username,
      })),
      onFilter: (value, record) => record.username.indexOf(value) === 0,
    },
    {
      title: <span className="custom-title">Quantity</span>,
      dataIndex: "quantity",
      className: "quantity",
    },
    {
      title: <span className="custom-title">Order Timing</span>,
      dataIndex: "createdAt",
      render: (dateTime) => formatDate(dateTime),
      filters: [
        { text: "Today", value: "Today" },
        { text: "Weekly", value: "Weekly" },
        { text: "Current Month data", value: "Monthly" },
      ],

      onFilter: (value, record) => {
        const itemDate = new Date(record.createdAt);
        const now = new Date();

        if (value === "Weekly") {
          const startOfWeek = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - now.getDay()
          );
          const endOfWeek = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + (6 - now.getDay())
          );
          return itemDate >= startOfWeek && itemDate <= endOfWeek;
        } else if (value === "Monthly") {
          const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

          const endOfMonth = new Date(
            new Date(now.getFullYear(), now.getMonth() + 1, 0)
          );
          return itemDate >= startOfMonth && itemDate <= endOfMonth;
        } else if (value === "Today") {
          const startOfDay = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
          );
          const endOfDay = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1
          );
          return itemDate >= startOfDay && itemDate < endOfDay;
        }
        return false;
      },
    },
    {
      title: <span className="custom-title">CheckBox</span>,
      dataIndex: "",
      render: (_, record) => (
        <Checkbox
          onClick={() => handleCheckBox(record)}
          checked={checkSelectedRecords(record)}
          disabled={checkDisabledRecords(record)}
        />
      ),
    },

    {
      title: <span className="custom-title">Cancel Order</span>,
      dataIndex: "",
      render: (foodId, record) => (
        < DeleteOutlined className="deleteOrder" onClick={() => handleCancel(record)} />
      ),
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    // setFilteredInfo(filters.createdAt);
  };
  const handleDatePickerChange = (date, dateString) => {
    setSelectedMonth(moment(dateString));
  };
  // Function to export data to Excel for the selected month
  const exportToExcel = () => {
    if (!selectedMonth) {
      // Show an error message if no month is selected
      Swal.fire({
        title: "Please Select month",
        icon: "error",
      });
      return;
    }
    // Filter data based on the selected month
    const filteredData = orderplaced.filter(
      (item) => moment(item.createdAt).month() === selectedMonth.month());
    const foodQuantities = {};
    // Iterate through filtered data to calculate quantities
    filteredData.forEach((item) => {
      const { food_id, quantity } = item;
      if (foodQuantities[food_id]) {
        // If food_id already exists, add the quantity to the existing value
        foodQuantities[food_id] += quantity;
      } else {
        // If food_id doesn't exist, initialize it with the quantity
        foodQuantities[food_id] = quantity;
      }
    });
    // Create an array of objects containing foodname and summed quantity
    const finalData = Object.keys(foodQuantities).map((food_id) => ({

      foodname: orderplaced.find((food) => food.food_id === food_id)?.foodname,
      quantity: foodQuantities[food_id]
    }));

    const worksheet = XLSX.utils.json_to_sheet(finalData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `Data_${selectedMonth.format("YYYY-MM")}`
    );
    XLSX.writeFile(workbook, `data_${selectedMonth.format("YYYY-MM")}.xlsx`);
  };
  function pagination(orderplaced) {
    if (orderplaced.length !== 0 || orderplaced.length !== undefined) {
      return orderplaced;
    }
  }

  return (
    <>
      <div className="loaderOuter">
        {
          loader ? (
            <div className="loaderSpin">
              <Spin size="large" />
            </div >
          ) : (<>
            {orderplaced.length > 0 ? (
              <>
                <Card>
                  <Table
                    // rowClassName={rowstyling}
                    columns={columns}
                    loading={loader}
                    dataSource={orderplaced.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )}
                    onChange={onChange}
                    pagination={pagination}
                    className="table-wrapper"
                  />
                </Card>
                <>
                  <Row className="d-flex justify-content-between align-items-center ">
                    <Col span={12}>
                      <DatePicker picker="month" onChange={handleDatePickerChange} className='datePicker' />
                      <Button className="order-btns" onClick={() => exportToExcel()}>
                        Export data
                      </Button>
                    </Col>

                    <Col span={12} className="text-right">
                      <Button
                        className="order-btns"
                        onClick={() => handleCheckBoxData()}
                        disabled={ischecked}
                      >
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <div style={{ width: "500px", height: "500px" }}>
                    <Image src={orderhistroy} preview={false} />
                    <h1 style={{ textAlign: "center", fontSize: "25px" }}>
                      No Order Placed Yet
                    </h1>
                  </div>
                </div>
              </>
            )}
          </>)}
      </div>
    </>
  )
};
export default Orderlist;
