import React, { useState } from "react";
import { UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../styles/updateTicket.css";
import {
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Upload,
  Select,
  Space,
  Image,
  
} from "antd";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import * as DispatchService from "../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { Title } = Typography;
function UpdateTicket() {
  const [fileList, setFileList] = useState([]);

  const location = useLocation();
  let receivedData = location.state.data;
  receivedData.SlackName = receivedData.SlackName === 'undefined' ? '-' : receivedData.SlackName;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [
    {
      label: "Morning",
      value: "Morning",
    },
    {
      label: "Afternoon",
      value: "Afternoon",
    },
    {
      label: "Evening",
      value: "Evening",
    },
  ];
  const props = {
    beforeUpload: (file) => {
      if (file.size <= 500 * 1024) {
        console.info("fileList", fileList);
        if (fileList < 1) {
          setFileList([...fileList, file]);
          return false;
        } else {
          toast.error("you have already selected one file", {
            autoClose: 1000,
            position: "top-center",
          });
          return false;
        }
      } else {
        toast.error("Maximum upload size  500 KB ", {
          autoClose: 1000,
          position: "top-center",
        });
        window.alert("Maximum upload size exceeded");
      }
    },
    fileList,
  };
  const onRemove = (remove) => {
    const updatedFileList = fileList.filter((item) => item.uid !== remove.uid);
    setFileList(updatedFileList);
  };
  const onFinish = (values) => {
    const formData = new FormData();
    formData.append("product_id", receivedData?._id);
    formData.append("name", values.name ? values.name : receivedData.name);
    formData.append("SlackName", values.SlackName ? values.SlackName : receivedData.SlackName);
    formData.append(
      "option",
      values.option ? values.option : receivedData.option
    );
    if (values.file !== undefined && values.file !== null) {
      let FileListing = values.file.file;
      formData.append("file", FileListing);
    } else {
      formData.append("filename", receivedData.filename);
      formData.append("path", receivedData.path);
    }
    dispatch(DispatchService.UpdateTckt(formData)).then((res) => {
      Swal.fire({
        title: "Succesfully Updated",
        icon: "success",
      }).then(() => {
        navigate("/list_ticket");
      });
    });
  };
  const handleBack = () => {
    navigate("/list_ticket");
  };
  return (
    <>
      <div className="updateticket">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            className="updateTicketForm"
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 6, offset: 2 }}
            md={{ span: 12 }}
          >
            <Button onClick={handleBack}>
              <ArrowLeftOutlined />
            </Button>
            <Title className="mb-15">Update Food Item Ticket</Title>
            <Title className="title" level={5}>
              Enter your food detail
            </Title>
            <Form
              initialValues={receivedData && receivedData !== "undefined" ? receivedData : {}}
              onFinish={onFinish}
              layout="vertical"
              className="row-col ticketForm"
            >
              <Form.Item
                className="username"
                label="Updated Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your ticketName"
                  },
                ]}
              >
                <Input placeholder="Ticket Name" />
              </Form.Item>

              <Form.Item label="Slack Name" name="SlackName"
                rules={[
                  {
                    required: true,
                    message: "Please input a Slack Name!",
                  },
                ]}
              >
                <Input type="text" placeholder="Slack Name" />
              </Form.Item>
              <Form.Item
                name="option"
                label=" Update Timing"
                className="username"
                rules={[
                  {
                    required: true,
                    message: "Please select option!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  optionLabelProp="label"
                  options={options}
                  optionrender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}></span>
                    </Space>
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Select file to upload"
                name="file"
                className="username"
              >
                <Upload
                  {...props}
                  accept="image/png, image/jpeg"
                  maxCount={1}
                  name="file"
                  onRemove={onRemove}
                >
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            className="update-img"
            style={{ padding: 12 }}
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <div className="imageWrapper1">
              <Image
                preview={false}
                style={{
                  borderRadius: "5px",
                  height: "250px",
                }}
                src={`https://foodorde-api.aleaspeaks.com/${receivedData.path}`}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
}
export default UpdateTicket;
