import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../styles/formticket.css";
import moment from "moment";
import {
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Upload,
  Select,
  Space,
  DatePicker,
  TimePicker,
  Radio,
  Spin
} from "antd";
import Swal from "sweetalert2";

import ticket from "../assets/images/ticket.png";
import * as DispatchService from "../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { Title } = Typography;
const { RangePicker } = DatePicker;
function FormTicket() {
  const [fileList, setFileList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [minimum, setMinimum] = useState(null);
  const [value, setValue] = useState("false");
  const [foodItems, setFoodItems] = useState([]);
  const [showFoodInput, setShowFoodInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDateChange = (dates, dateString) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      setSelectedTime(dateString);
    }
  };
  const options = [
    {
      label: "Morning",
      value: "Morning",
    },
    {
      label: "Afternoon",
      value: "Afternoon",
    },
    {
      label: "Evening",
      value: "Evening",
    },
    {
      label: "AllTime",
      value: "AllTime",
    },
  ];

  function disabledDate(current) {
    // Disable all dates before today's date
    return current && current < moment().startOf("day");
  }
  
  const onOk = (value) => { };
  const onStartChange = (time, timeString) => {
    setMinimum(timeString, time);
  };
  const props = {
    beforeUpload: (file) => {
      if (file.size <= 500 * 1024) {
        if (fileList < 1) {
          setFileList([...fileList, file]);
          return false;
        } else {
          toast.error("you have already selected one file", {
            autoClose: 1000,
            position: "top-center",
          });
        }
      } else {
        // window.alert("Maximum upload size exceeded");
        toast.error("Maximum upload size  should be 500 KB", {
          autoClose: 1000,
          position: "top-center",
        });
      }
    },
    fileList,
  };
 
  const onRemove = (remove) => {
    const updatedFileList = fileList.filter((item) => item.uid !== remove.uid);
    setFileList(updatedFileList);
  };
  const onFinish = (values) => {
    setLoader(true)
    const formData = new FormData();
    const formattedFoodItems = foodItems.join(", ");
    // formData.append("name", values?.name);
    if (value === "true") {
      formData.append("name", `${values?.name},${formattedFoodItems}`);
    } else {
      formData.append("name", values?.name);
    }
    formData.append("selectedTime", selectedTime);
    formData.append("premix", values?.premix);

    if (minimum) {
      formData.append("minimumStartTime", minimum);
    }
    formData.append("option", values?.option);
    formData.append("SlackName", values?.SlackName);
    let FileListing = values?.file?.fileList;

    FileListing.forEach((file) => {
      formData.append("file", file.originFileObj);
    });
    dispatch(DispatchService.CreateFormTckt(formData))
      .then((res) => {
        setLoader(false)
        Swal.fire({
          title: "Succesfully Added",
          icon: "success",
        }).then(() => {
          navigate("/list_ticket");
        });
      })
      .catch(() => { });
  };
  const onRadioChange = (e) => {
    setValue(e.target.value);
  };
  const addFoodItem = () => {
    setShowFoodInput(true);
    setFoodItems([...foodItems, ""]);
  };
  const updateFoodItem = (index, value) => {
    // Update the value of a food item in the list
    const updatedFoodItems = [...foodItems];
    updatedFoodItems[index] = value;
    setFoodItems(updatedFoodItems);
  };
  const removeFoodItem = (index) => {
    // Remove a food item from the list
    const updatedFoodItems = foodItems.filter((_, i) => i !== index);
    setFoodItems(updatedFoodItems);
  };
  return (
    <>
      <div className="FormTicket">
        {
          loader ? (
            <div>
              <Spin />
            </div >
          ) : ('')
        }
        <Row gutter={24} justify="space-around">
          <Col
            className="signinRow"
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <Title className="mb-15">Food Item Ticket</Title>
            <Title className="title" level={5}>
              Enter your food detail
            </Title>
            <Form
              onFinish={onFinish}
              layout="vertical"
              className="row-col ticketForm"
            >
              <Form.Item
                name="premix"
                label=" please Select one of them for adding premix meal"
              >
                <Radio.Group onChange={onRadioChange} value={value}>
                  <Space direction="vertical">
                    <Radio value="false">No</Radio>
                    <Radio value="true">Yes</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                className="foodName"
                label="Food Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your ticketName!",
                  },
                ]}
              >
                <Input placeholder="Ticket Name" />
              </Form.Item>
              {showFoodInput && (
                <Form.Item>
                  {foodItems.map((item, index) => (
                    <div key={index} className="food-item">
                      <Input
                        placeholder="Food Name"
                        value={item}
                        onChange={(e) => updateFoodItem(index, e.target.value)}
                      />
                      <Button onClick={() => removeFoodItem(index)}>
                        Remove
                      </Button>
                    </div>
                  ))}
                </Form.Item>
              )}
              {value === "true" && (
                <Button
                  type="dashed"
                  block
                  className="add-btn"
                  onClick={addFoodItem}
                >
                  + Add Food
                </Button>
              )}

              <Form.Item
                name="option"
                label="Option"
                className="username"
                rules={[
                  {
                    required: true,
                    message: "Please select option!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "60%",
                  }}
                  placeholder="Please select"
                  optionLabelProp="label"
                  options={options}
                  optionrender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}></span>
                    </Space>
                  )}
                />
              </Form.Item>
              <Form.Item label="Minimum Start Time" name="minimumStartTime">
                <TimePicker
                  onChange={onStartChange}
                  format="HH:mm"
                  className="formTiming"
                />
              </Form.Item>
              <Form.Item
                label="Select file to upload"
                name="file"
                className="username"
                rules={[
                  {
                    required: true,
                    message: "Please select file!",
                  },
                ]}
              >
                <Upload
                  {...props}
                  accept="image/png, image/jpeg"
                  maxCount={1}
                  name="file"
                  onRemove={onRemove}
                >
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>

              <Form.Item label="Slack Name" name="SlackName"
              rules={[
                {
                  required: true,
                  message: "Please input your ticketName!",
                },
              ]}
              >
                <Input type="text" placeholder="Slack Name" />
              </Form.Item>
              <Form.Item
                label="Start Date And End Date"
                name="time"
                rules={[
                  {
                    required: true,
                    message: "Please select file!",
                  },
                ]}
              >
                <RangePicker
                  onChange={handleDateChange}
                  onOk={onOk}
                  format="YYYY-MM-DD"
                  className="formTiming"
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "60%" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            className="sign-img"
            style={{ padding: 12 }}
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <div className="imageWrapper" style={{ padding: "2rem" }}>
              <img src={ticket} alt="" />
            </div>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
}
export default FormTicket;
