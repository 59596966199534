const initialState = {
  userType: null,
  userID: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_TYPE":
      return {
        ...state,
        userType: action.payload,
      };
    case "SET_USER_ID":
      return {
        ...state,
        userID: action.payload,
      };
    case "RESET_USER_STATE":
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
