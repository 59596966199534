import React, { useState, useEffect } from "react";
import { Card, Spin, Table, Rate } from "antd";
import * as DispatchService from "../../services/DispatchService/DispatchService";
import * as FetchService from "../../services/FetchService/FetchService";
import { useDispatch } from "react-redux";
import "../../styles/vieworder.css";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
const ViewOrder = () => {
  const [allOrderHistroy, setAllOrderHistroy] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [allRatingData, setAllRatingData] = useState({});
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  const dispatch = useDispatch();
  const loggedInUserRole = useSelector((state) => state.user.userID);
  const getuserid = loggedInUserRole;
  useEffect(() => {
    orderhistory();
    fetchTime();
    fetchRatings()
  }, []);
  const fetchTime = () => {
    dispatch(FetchService.TimeList()).then((res) => {
      setTimeList(res);
    });
  };
  const currentTime = new Date();
  const currentEndTime = new Date();
  const filterFoodByTiming = () => {
    if (allOrderHistroy) {
      const filteredFood = allOrderHistroy.filter((food) => {
        const createdAt = new Date(food.createdAt);
        for (let i = 0; i < timeList.length; i++) {
          let startTime = timeList[i].start_time;
          let endTime = timeList[i].end_time;
          const [hours, minutes] = startTime.split(":").map(Number);
          const [hrs, mints] = endTime.split(":").map(Number);
          currentTime.setHours(hours, minutes, 0, 0);
          currentEndTime.setHours(hrs, mints, 0, 0);
          if (createdAt >= currentTime && createdAt <= currentEndTime) {
            return allOrderHistroy;
          } else {
            // console.log("no match");
          }
        }
      });
      return filteredFood;
    }
  };
  filterFoodByTiming();
  const orderhistory = () => {
    setLoader(true);
    dispatch(DispatchService.OrderHistory({ getuserid })).then((result) => {
      let filterFoodRecords = result?.filter((item) => {
        return item.name !== undefined;
      });
      setAllOrderHistroy(filterFoodRecords.reverse());
      setLoader(false);
    });
  };
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleString();
  };
  //.............................................rating...............//
  const fetchRatings = () => {
    dispatch(DispatchService.getRating(getuserid))
      .then((response) => {
        console.log(response, "response  ")
        setAllRatingData(response);
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  const handleRating = (rating, foodId) => {
    const ratedFood = allOrderHistroy.find((food) => food.food_id === foodId);
    const ratingDatadetail = desc[rating - 1];
    if (ratedFood) {
      const ratingData = {
        ...allRatingData,
        foodName: ratedFood.name,
        rating: ratingDatadetail,
        userId: ratedFood.user_id,
        foodId: ratedFood.food_id,
        value: rating,
      };

     
      dispatch(DispatchService.Rating(ratingData))
        .then((response) => {
          setAllRatingData(response);
          Swal.fire({
            title: "Thanks for giving rating",
            icon: "success",
          });

        })
        .catch((error) => {
          console.error("Error adding rating:", error);
        });
    }
  };



  // const handleRating = (rating, foodId, createdAt) => {
  //   const ratedFood = allOrderHistroy.find((food) => food.food_id === foodId);
  //   const ratingDatadetail = desc[rating - 1];
  //   const createdMonth = new Date(createdAt).getMonth();
  //   const currentMonth = new Date().getMonth();

  //   if (createdMonth === currentMonth) {
  //     if (ratedFood) {
  //       const ratingData = {
  //         ...allRatingData,
  //         foodName: ratedFood.name,
  //         rating: ratingDatadetail,
  //         userId: ratedFood.user_id,
  //         foodId: ratedFood.food_id,
  //         value: rating,
  //       };
  //       dispatch(DispatchService.Rating(ratingData))
  //         .then((response) => {
  //           setAllRatingData(response);
  //           Swal.fire({
  //             title: "Thanks for giving rating",
  //             icon: "success",
  //           });
  //         })
  //         .catch((error) => {
  //           console.error("Error adding rating:", error);
  //         });
  //     }
  //   } else {
  //     Swal.fire({
  //       title: "Rating disabled",
  //       text: "You can only rate items from the current month.",
  //       icon: "warning",
  //     });
  //   }
  // };

  const checkRating = (item) => {
    if (allRatingData?.length > 0) {
      let rating;
      allRatingData?.find((record) => {
        if (record.food_id === item.name) {
          rating = record.value;
        }
        return rating;
      });
      return rating;
    } else {
      return 0;
    }
  };
  const columns = [
    {
      title: "S. No.",
      dataIndex: "S_No.",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Food Item",
      dataIndex: "item.",
      key: "item",
      render: (text, record) => <img src={`https://foodorde-api.aleaspeaks.com/${record.path}`} alt={record.name} width={75} />,
    },
    {
      title: 'Food Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.name,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Placed at',
      dataIndex: 'createdAt',
      key: 'placed',
      render: (text) => formatDateTime(text),
    },
    //   {
    //     title: "Rating",
    //     dataIndex: "rating",
    //     key: "rating",
    //     render: (text, record) => {
    //       // Assuming record.createdAt is in a valid format and represents the intended time
    //       const createdMonth = new Date(record.createdAt);
    //       const getcreatedMonth = createdMonth.getMonth() + 1
    // // Adding 1 to get human-readable month
    //       const currentMonth = new Date().getMonth() + 1; // Adding 1 to get human-readable mont

    //       // <>
    //       if (getcreatedMonth === currentMonth) {
    //         return (
    //           <>
    //         <Rate
    //             tooltips={desc}
    //             onChange={(value) =>
    //               handleRating(value, record.food_id)
    //             }
    //             value={checkRating(record)}
    //           />
    //           {
    //             allRatingData[record.food_id]?.rating && (
    //               <span className="rating">
    //                 {allRatingData[record.food_id].rating}
    //               </span>
    //             )
    //             }
    //             </>
    //         )
    //       }
    //       else {
    //         <Rate disabled/>
    //       }

    //       // </>
    //     },
    //   },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (text, record) => {
        // Assuming record.createdAt is in a valid format and represents the intended time
        const createdMonth = new Date(record.createdAt);
        const getcreatedMonth = createdMonth.getMonth() + 1; // Adding 1 to get human-readable month
        const currentMonth = new Date().getMonth() + 1; // Adding 1 to get human-readable month

        if (getcreatedMonth === currentMonth) {
          return (
            <>
              <Rate
                tooltips={desc}
                onChange={(value) => handleRating(value, record.food_id)}
                value={checkRating(record)}
              />
              {allRatingData[record.food_id]?.rating && (
                <span className="rating">
                  {allRatingData[record.food_id].rating}
                </span>
              )}
            </>
          );
        } else {
          return <Rate disabled />;
        }
      },
    }

  ];

  return (
    <div className="viewOrderMainDiv">
      {
        loader ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh' }}>
            <Spin size="large" />
          </div >
        ) : <Card>
          <Table columns={columns} dataSource={allOrderHistroy} />
        </Card>
      }

    </div>
  );
};
export default ViewOrder;
