import Http from "../../Http";
import { fetchDetail_Url } from "../../Helper";

//orderlist
export const OrderList = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getorderlist")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

//listTicket
export const FoodList = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "listticket")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
//TimeList
export const TimeList = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "timingDetail")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
//userpanel
export const UserDetail = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "usermodel")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
//get all rating
export const AllRating = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "allratingData")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const AllAnncounment = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getallanncoument")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getallCheckboxData = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getCheckboxData")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getAllAssignTckt = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getallAssignTckt")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getassignCouponLength = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getassignedCouponsNo")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getStationary = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllStationary")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getAllHardwareData = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllHardware")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getAllocatedUser = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllcoatedHardware")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getAllocatedList = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllcoatedStationary")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getCategory = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllCategory")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getEmail = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllemail", data)
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const getOtherItem = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllOtherItem")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const getAllcoatedOtherItem = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllcoatedOther")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const getAllFoodData = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllFood")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
export const getAllocatedFoodUser = () => {
  return () =>
    new Promise((resolve, reject) => {
      Http.get(fetchDetail_Url + "getAllcoatedFood")
        .then((res) => {

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};
