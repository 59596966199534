import React, { useState, useEffect } from "react";
import { Form, Input, Checkbox, Button, DatePicker, Spin } from "antd";
import * as FetchService from "../services/FetchService/FetchService";
import * as DispatchService from "../services/DispatchService/DispatchService";
import { useDispatch } from "react-redux";
const { RangePicker } = DatePicker;
function Announcement() {
  const [foodList, setFoodList] = useState([]);
  const [selectedFoodId, setSelectedFoodId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    fetchFoodList();
    fetchanncoument()
  }, []); // Fetch the food list on component mount
  const fetchFoodList = () => {
    setLoader(true)
    dispatch(FetchService.FoodList())
      .then((res) => {
        setFoodList(res);
        setLoader(false)
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchanncoument = () => {
    setLoader(true)
    dispatch(FetchService.AllAnncounment())
      .then((res) => {
        setLoader(false)
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleCheckboxChange = (foodId) => {
    setSelectedFoodId(foodId);
  };
  const handleDateChange = (dates, dateString) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      setSelectedTime(dateString);
    }
  };
  const onFinish = (values) => {
    values.selectedTime = selectedTime;
    dispatch(DispatchService.AnnouncmentApi(values))
      .then((res) => {
        form.resetFields("");
        setSelectedTime('');
        setEndDate('');
        setStartDate('');
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div>
      {
        loader ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh' }}>
            <Spin size="large" />
          </div >
        ) : (<Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
          className="row-col ticketForm"
        >
          <Form.Item
            label="Select Food"
            name="checkbox"
            rules={[
              {
                required: true,
                message: "Please select one of them",
              },
            ]}
          >
            <Checkbox.Group onChange={handleCheckboxChange}>
              {foodList.map((food) => {
                return (
                  <Checkbox key={food._id} value={food._id}>
                    {food.premix ? `${food.name}(Premix meal)` : food.SlackName}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            label="Please Write Announcement"
            name="Text"
            rules={[
              {
                required: true,
                message: "Please Write Announcement",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Start Date And End Date"
            name="selectedTime"
            rules={[
              {
                required: true,
                message: "Please select Date",
              },
            ]}
          >
            <RangePicker
              onChange={handleDateChange}

              format="YYYY-MM-DD"
              className="formTiming"
            />
          </Form.Item>
          <Button className="formbtn" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>)
      }
    </div>
  );
}

export default Announcement;
