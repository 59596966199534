import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, Card, Table } from 'antd'
import { useDispatch } from "react-redux";
import * as DispatchService from "../../../services/DispatchService/DispatchService";
import * as FetchService from "../../../services/FetchService/FetchService";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import '../../../styles/Category.css'

const Category = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [updateModal, setUpdateModel] = useState(false)
    const [category, setCategory] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [initialValues, setInitialValues] = useState({ category: '', quantity: '' });
    const handleSubmit = (values) => {
        const { category, quantity } = values;
        const combinedData = {
            category,
            quantity,
        }
        dispatch(DispatchService.addCategory(combinedData)).then(
            (response) => {
                Swal.fire({
                    title: "Succesfully Added",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setModal(false)
                        getdata()
                    }
                })

            })
        form.resetFields()
    }
    const handleCategory = () => {
        setModal(true)
    }
    const handleCancel = () => {
        setModal(false)
    }
    const getdata = () => {
        dispatch(FetchService.getCategory()).then((response) => {
            setCategory(response)
        })
    }
    useEffect(() => {
        getdata()

    }, [])

    const handleDeleteCategory = (data) => {
        Swal.fire({
            title: "Are you sure you want to delete this item?",
            text: "This action cannot be undone",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                const categoryId = data._id
                dispatch(DispatchService.deleteCategory({ categoryId })).then((response) => {
                    if (response.status === 200 || response.message === "Category and related data deleted successfully") {
                        Swal.fire({
                            title: "Succesfully Deleted",
                            icon: "success",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setModal(false)
                                getdata()
                            }

                        })

                    } else {
                        // console.log('failed to delete 1')
                    }

                })
            } else {
                // console.log("failed to delete 2")
            }
        })
    }

    const handleUpdateCategory = (data) => {
        setUpdateModel(true)
        setCategoryId(data._id)
        const intinaldata = ({
            category: data.category,
            quantity: data.quantity
        });
        form.setFieldsValue(intinaldata);
    }

    const handleUpdateSubmit = (values) => {
     
        const { category, quantity } = values;
        const data = {
            category,
            quantity,
            categoryId
        }
        dispatch(DispatchService.updateCategory({ data })).then((response) => {
            if (response.message === "Category  Updated successfully") {
                Swal.fire({
                    title: "Succesfully Updated",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUpdateModel(false)
                        setInitialValues({ category: '', quantity: '' })
                        getdata()
                    }
                })
            }
        })
    }

    const handleUpdateCancel = () => {
        setUpdateModel(false)
        setInitialValues({ category: '', quantity: '' })
    }

    const columns = [
        {
            title: "S. No.",
            dataIndex: "S_No.",
            key: "index",
            render: (text, record, index) => index + 1,
        },

        {
            title: <span className="custom-title">Category Name</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.category}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Quantity</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.quantity}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title"> Remaining Quantity</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.remainingQuantity}</span>
                </div>
            ),
        },
        {
            title: <span className="custom-title">Action</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <Button className="DeleteButton" onClick={() => handleDeleteCategory(rec)}><MdDelete />Delete</Button>
                    <Button className="UpdateButton" onClick={() => handleUpdateCategory(rec)} > <FaEdit />Edit</Button>
                </div>
            ),
        },
    ];

    return (
        <>
            <div>
                <Button type="primary" onClick={handleCategory}>Add Category</Button>
            </div>
            <Modal title="Category" open={modal} onCancel={handleCancel} footer={null}>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>

                    <Form.Item
                        label="Category"
                        name="category"
                        rules={[{ required: true, message: 'Please input the category!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[{ required: true, message: 'Please input the quantity!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Add</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Card>
                {category.length > 0 ? (
                    <Table
                        className="time-table"
                        columns={columns}
                        pagination={true}
                        dataSource={category}

                    />
                ): ""}
                
            </Card>

            <Modal title=" Update Category" open={updateModal} onCancel={handleUpdateCancel} footer={null}>
                <Form form={form} layout="vertical" onFinish={handleUpdateSubmit}>
                    <Form.Item
                        label="Category"
                        name="category"
                        rules={[{ required: true, message: 'Please input the  Updated category!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[{ required: true, message: 'Please input the  updated quantity!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Update</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default Category