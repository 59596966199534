import React from "react";
import { Menu, Drawer } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

import {
  ClockCircleOutlined,
  ShopOutlined,
  CloseCircleOutlined,

} from "@ant-design/icons";
import { SiOpensourcehardware } from "react-icons/si";
import { GiBookshelf } from "react-icons/gi";
import { BiSolidCategory } from "react-icons/bi";
import { IoFastFoodOutline } from "react-icons/io5";
import "../../styles/sidenav.css";
import { IoSettingsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { MdManageAccounts, MdOutlineInventory } from "react-icons/md";
const { SubMenu } = Menu;
function Sidenav({ color, isDrawerOpen, DrawerClose }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const userData = useSelector((state) => state);
  let getitem = userData.user.userType;
  const navigate = useNavigate()
  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];
  const Users = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const FoodList = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];
  const handleClick = () => {
    navigate('/')
  }
  const handleadminPage = () => {
    navigate('/dashboard')
  }


  const handleHardware = () => {
    navigate('/hardware');
  };

  const handleStationary = () => {
    navigate('/stationary');
  };

  const handleFood = () => {
    navigate('/food');
  };
  const handleOther= () => {
    navigate('/other');
  };


  return (
    <>
      {isDrawerOpen ? (
        <>
          <Drawer
            placement="left"
            onClose={DrawerClose}
            open={isDrawerOpen}
            width={300}
            closeIcon={
              <div style={{ float: "right" }}>
                <CloseCircleOutlined />
              </div>
            }
          >
            {getitem === 2 ? (
              <>
                <div className="brand" >
                  <img src={logo} alt="" style={{ width: "100px", cursor: "pointer" }} onClick={handleadminPage} />
                  <span onClick={handleadminPage} style={{ cursor: "pointer" }} >Alea Food Order</span>
                </div>
                <hr />
                <div
                  className="usermenu"
                  style={{
                    height: "500px",
                  }}
                >
                  <Menu theme="light" mode="inline" className="menu-wrapper">
                    <Menu.Item key="1" className="menu-item">
                      <NavLink to="/dashboard">
                        <span className="icon-change">{dashboard}</span>
                        <span className="label">Dashboard</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="2" className="menu-item">
                      <NavLink to="/user_panel">
                        <span
                          className="icon-change"
                          style={{
                            background: page === "Users" ? color : "",
                          }}
                        >
                          {Users}
                        </span>
                        <span className="label">Users List</span>
                      </NavLink>
                    </Menu.Item>{" "}
                    <Menu.Item key="3" className="menu-item">
                      <NavLink to="/time_list">
                        <span
                          className="icon-change"
                          style={{
                            background: page === "TimeList" ? color : "",
                          }}
                        >
                          {<ClockCircleOutlined />}
                        </span>
                        <span className="label">Time List</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="5" className="menu-item">
                      <NavLink to="/list_ticket">
                        <span
                          className="icon-change"
                          style={{
                            background: page === "FoodList" ? color : "",
                          }}
                        >
                          {FoodList}
                        </span>
                        <span className="label">Food List</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="6" className="menu-item">
                      <NavLink to="/announcement">
                        <span className="icon-change">{dashboard}</span>
                        <span className="label">Announcement</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="6" className="menu-item" style={{
                      background: page === "FoodList" ? color : "",
                    }}>
                      <NavLink to="/settings">
                        <span className="icon-change">{<IoSettingsOutline/>}</span>
                        <span className="label">Settings</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="7" className="menu-item">
                      <NavLink to="/order_list">
                        <span
                          className="icon-change"
                          style={{
                            background: page === "FoodList" ? color : "",
                          }}
                        >
                          {FoodList}
                        </span>
                        <span className="label">Order List</span>
                      </NavLink>
                    </Menu.Item>


                    <Menu.Item key="6">
                      <NavLink to="/category">
                        <span
                          className="icon"
                          style={{
                            background: page === "FoodList" ? color : "",
                          }}
                        >
                          <BiSolidCategory />
                        </span>
                        <span className="label">Category</span>
                      </NavLink>
                    </Menu.Item>

                    <SubMenu key="sub1" title={
                      <>
                        <MdManageAccounts />
                        <span>Inventory</span>
                      </>
                    }>
                      <Menu.Item key="hardware" onClick={handleHardware} icon={<SiOpensourcehardware />}>
                        Hardware
                      </Menu.Item>
                      <Menu.Item key="stationary" onClick={handleStationary} icon={<GiBookshelf />}>
                        Stationary
                      </Menu.Item>
                      <Menu.Item key="food" onClick={handleFood} icon={<IoFastFoodOutline />}>
                        Food
                      </Menu.Item>
                      <Menu.Item key="food" onClick={handleOther} icon={<MdOutlineInventory />}>
                        Other
                      </Menu.Item>
                    </SubMenu>
                  </Menu>

                </div>
                <hr />
              </>
            ) : (
              <>
                <div className="brand" >
                  <img src={logo} alt="" style={{ width: "100px", cursor: "pointer" }} onClick={handleClick} />
                  <span onClick={handleClick} style={{ cursor: "pointer" }} >Alea Food Order</span>
                </div>
                <hr />
                <div className="usermenu" style={{ height: "500px" }}>
                  <Menu theme="light" mode="inline" className="menu-wrapper">
                    <Menu.Item key="1" className="menu-item">
                      <NavLink to="/order_food">
                        <span
                          className="icon-change"
                        // style={{
                        //   background: page === "dashboard" ? color : "",
                        // }}
                        >
                          {dashboard}
                        </span>
                        <span className="label">Order Food</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="2" className="menu-item">

                      <NavLink to="/assgined_ticket">

                        <span
                          className="icon-change"
                          style={{
                            background: page === "dashboard" ? color : "",
                          }}
                        >
                          {FoodList}
                        </span>
                        <span className="label">Assigned coupons</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <NavLink to="/order_timing">
                        <span
                          className="icon-change"
                          style={{
                            background: page === "dashboard" ? color : "",
                          }}
                        >
                          {<ClockCircleOutlined />}
                        </span>
                        <span className="label">Order Timing</span>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="3" className="menu-item">
                      <NavLink to="/view_order">
                        <span
                          className="icon-change"
                          style={{
                            background: page === "dashboard" ? color : "",
                          }}
                        >
                          {<ShopOutlined />}
                        </span>
                        <span className="label">View Order</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="3" className="menu-item">
                      <NavLink to="/infra">
                        <span
                          className="icon-change"
                          style={{
                            background: page === "dashboard" ? color : "",
                          }}
                        >
                          {<ShopOutlined />}
                        </span>
                        <span className="label">Infra</span>
                      </NavLink>
                    </Menu.Item>
                  </Menu>
                </div>
                <hr />
              </>
            )}
          </Drawer>
        </>
      ) : (
        <>
          {getitem === 2 ? (
            <>
              <div className="brand" >
                <img src={logo} alt="" onClick={handleadminPage} style={{ cursor: "pointer" }} />
                <span onClick={handleadminPage} style={{ cursor: "pointer" }} >Alea Food Order</span>
              </div>
              <hr />
              <div className="usermenu" style={{ height: "500px" }}>
                <Menu theme="light" mode="inline">
                  <Menu.Item key="1">
                    <NavLink to="/dashboard">
                      <span
                        className="icon"
                        style={{
                          background: page === "dashboard" ? color : "",
                        }}
                      >
                        {dashboard}
                      </span>
                      <span className="label">Dashboard</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <NavLink to="/user_panel">
                      <span
                        className="icon"
                        style={{
                          background: page === "Users" ? color : "",
                        }}
                      >
                        {Users}
                      </span>
                      <span className="label">Users List</span>
                    </NavLink>
                  </Menu.Item>{" "}
                  <Menu.Item key="3">
                    <NavLink to="/time_list">
                      <span
                        className="icon"
                        style={{
                          background: page === "TimeList" ? color : "",
                        }}
                      >
                        {<ClockCircleOutlined />}
                      </span>
                      <span className="label">Time List</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="5">
                    <NavLink to="/list_ticket">
                      <span
                        className="icon"
                        style={{
                          background: page === "FoodList" ? color : "",
                        }}
                      >
                        {FoodList}
                      </span>
                      <span className="label">Food List</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <NavLink to="/order_list">
                      <span
                        className="icon"
                        style={{
                          background: page === "FoodList" ? color : "",
                        }}
                      >
                        {FoodList}
                      </span>
                      <span className="label">Order List</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <NavLink to="/category">
                      <span
                        className="icon"
                        style={{
                          background: page === "FoodList" ? color : "",
                        }}
                      >
                        <BiSolidCategory />
                      </span>
                      <span className="label">Category</span>
                    </NavLink>
                  </Menu.Item>
                  <SubMenu key="sub1" title={
                    <>
                      <MdManageAccounts />
                      <span className="label">Inventory</span>
                    </>
                  }>
                    <Menu.Item key="hardware" onClick={handleHardware} icon={<SiOpensourcehardware />}>
                      Hardware
                    </Menu.Item>
                    <Menu.Item key="stationary" onClick={handleStationary} icon={<GiBookshelf />}>
                      Stationary
                    </Menu.Item>
                    <Menu.Item key="food" onClick={handleFood} icon={<IoFastFoodOutline />}>
                      Food
                    </Menu.Item>
                      <Menu.Item key="food" onClick={handleOther} icon={<MdOutlineInventory />}>
                      Other
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="8">
                    <NavLink to="/announcement">
                      <span
                        className="icon"
                        style={{
                          background: page === "FoodList" ? color : "",
                        }}
                      >
                        {FoodList}
                      </span>
                      <span className="label">Announcement</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="8">
                    <NavLink to="/settings">
                      <span
                        className="icon"
                        style={{
                          background: page === "FoodList" ? color : "",
                        }}
                      >
                          {<IoSettingsOutline />}
                      </span>
                      <span className="label">Settings</span>
                    </NavLink>
                  </Menu.Item>
                </Menu>
              </div>
              <hr />
            </>
          ) : (
            <>
              <div className="brand">
                <img src={logo} alt="" onClick={handleClick} style={{ cursor: "pointer" }} />
                <span onClick={handleClick} style={{ cursor: "pointer" }} >Alea Food Order</span>
              </div>
              <hr />
              <div className="usermenu" style={{ height: "500px" }}>
                <Menu theme="light" mode="inline">
                  <Menu.Item key="1">
                    <NavLink to="/order_food">
                      <span
                        className="icon"
                        style={{
                          background: page === "dashboard" ? color : "",
                        }}
                      >
                        {dashboard}
                      </span>
                      <span className="label">Order Food</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <NavLink to="/assgined_ticket">
                      <span
                        className="icon"
                        style={{
                          background: page === "dashboard" ? color : "",
                        }}
                      >
                        {FoodList}
                      </span>
                      <span className="label">Assigned coupons</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <NavLink to="/order_timing">
                      <span
                        className="icon"
                        style={{
                          background: page === "dashboard" ? color : "",
                        }}
                      >
                        {<ClockCircleOutlined />}
                      </span>
                      <span className="label">Order Timing</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <NavLink to="/view_order">
                      <span
                        className="icon"
                        style={{
                          background: page === "dashboard" ? color : "",
                        }}
                      >
                        {<ShopOutlined />}
                      </span>
                      <span className="label">View Order</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <NavLink to="/infra">
                      <span
                        className="icon"
                        style={{
                          background: page === "dashboard" ? color : "",
                        }}
                      >
                        {<ShopOutlined />}
                      </span>
                      <span className="label">Infra</span>
                    </NavLink>
                  </Menu.Item>

                </Menu>
              </div>
              <hr />
            </>
          )}
        </>
      )
      }
    </>
  );
}
export default Sidenav;
