import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as FetchService from "../../services/FetchService/FetchService";
import { Table, DatePicker, Image } from "antd";
import moment from "moment";
import "../../styles/EChart.css";

function EChart() {
  const [mostOrderedUser, setMostOrderedUser] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const dispatch = useDispatch();

  const handleMonthChange = (date, dateString) => {
    if (dateString !== "" && dateString !== undefined && dateString !== null) {
      setSelectedMonth(moment(dateString));
    } else {
      setDefaultMonth();
    }
  };

  const setDefaultMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    setSelectedMonth(`${year}-${month}`);
  };
  useEffect(() => {
    if (selectedMonth === null) {
      setDefaultMonth();
    }
  }, [selectedMonth]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(FetchService.OrderList()).then((response) => {
        let filterRecords = response?.filter((item) => {
          
          return (
            item !== null &&
            moment(item?.createdAt).isSame(selectedMonth, "month")
          );
        });
        if (filterRecords.length > 0) {
          const userOrderCount = filterRecords.reduce((acc, curr) => {
            acc[curr.username] = (acc[curr.username] || 0) + 1;
            return acc;
          }, {});

          const sortedUsers = Object.keys(userOrderCount).sort(
            (a, b) => userOrderCount[b] - userOrderCount[a]
          );

          const mostOrdered = sortedUsers.map((username) => ({
            username,
            orders: userOrderCount[username],
            userimage: filterRecords.find((item) => item.username === username)
              ?.userimage,
          }));

          setMostOrderedUser(mostOrdered);
        } else {
          setMostOrderedUser([]);
        }
      });
    };

    fetchData();
  }, [selectedMonth, dispatch]);

  const columns = [
    {
      title: <span className="custom-address-title">Username</span>,
      dataIndex: "username",
      className: "custom-name-column",
      key: "name",
      // ...getColumnSearchProps("username"),

      render: (img, record) => (
        <div
          style={{
            display: "flex",
            marginLeft: "60px",
          }}
        >
          <Image
            src={record.userimage}
            preview={false}
            width={30}
            height={30}
            style={{
              borderRadius: "20px",
            }}
          />

          <span style={{ margin: "5px" }}>{img}</span>
        </div>
      ),
    },

    {
      title: <span className="custom-address-title">Number Of Order</span>,
      dataIndex: "quantity",
      className: "custom-name-column",
      key: "name",
      // ...getColumnSearchProps("username"),

      render: (img, record) => (
        <div
          style={{
            display: "flex",
            marginLeft: "60px",
          }}
        >
          <span style={{ margin: "5px" }}>{record.orders}</span>
        </div>
      ),
    },
  ];
  // const filteredData = orderplaced.filter((item) => {
  //   console.log(item, "item")
  //   return moment(item.date).isSame(selectedMonth, "month");
  // });
  return (
    <>
      <h1> User with most ordered </h1>
      <DatePicker
        defaultValue={selectedMonth}
        onChange={handleMonthChange}
        picker="month"
      />
      <Table
        columns={columns}
        dataSource={mostOrderedUser}
        pagination={mostOrderedUser.length}
        className="userTable"
      />
    </>
  );
}

export default EChart;
