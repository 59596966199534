import React, { useState, useEffect } from "react";
import * as FetchService from '../../services/FetchService/FetchService'
import { useDispatch } from "react-redux";
import { Table, Spin } from "antd";
const OrderTiming = () => {
    const [timeList, setTimeList] = useState([]);
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch()
    const columns = [
        {
            title: <span className="time-title"> Order Slot</span>,
            dataIndex: "option",
            key: "option",
            className: "Option-Name",
        },
        {
            title: <span className="custom-title">Timing</span>,
            dataIndex: "",
            key: "timing",
            render: (time, rec) => (
                <div className="timing">
                    <span>{rec.start_time}</span>
                    <span>-</span>
                    <span>{rec.end_time}</span>
                </div>
            ),
        },
    ]
    useEffect(() => {
        fetchTime();
    }, []);
    const fetchTime = () => {
       dispatch(FetchService.TimeList()).then((res) => {
            setTimeList(res);
            setLoader(false)
        });
    };
    return (
        <>
            <div className="viewOrderMainDiv">
                {
                    loader ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '50vh' }}>
                            <Spin size="large" />
                        </div >) : (timeList.length > 0 ? (
                            <Table
                                className="time-table"
                                columns={columns}
                                pagination={false}
                                dataSource={timeList}
                                loading={loader}/>
                        ) : (
                            <p
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "150px",
                                }}>
                                No timing is added{" "}
                            </p>
                        )
                    )}
            </div>
        </>
    )
}
export default OrderTiming
